import { Routes } from '@angular/router';

import { AppLayoutComponent } from './app-layout.component';
import { canUser } from './auth/permission.service';
import { HomeComponent } from './home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PresentationLegendComponent } from './presentation/presentation-legend.component';

export const routes: Routes = [
  { path: 'presentation-legend', component: PresentationLegendComponent },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.routes'),
      },
      {
        path: 'shop',
        canActivate: [canUser('viewShop')],
        loadChildren: () => import('./shop/shop.routes'),
      },
      {
        path: 'analysis',
        canActivate: [canUser('viewOrgAnalysis')],
        loadChildren: () => import('./analyze-org/analyze-org.routes'),
      },
      {
        path: 'admin',
        canActivate: [canUser('viewAdmin')],
        loadChildren: () => import('./admin/admin.routes'),
      },
      { path: '404', component: NotFoundComponent },
      { path: '**', component: NotFoundComponent },
    ],
  },
];
