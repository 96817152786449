import { __awaiter as e } from "tslib";
import { Auth as t } from "aws-amplify";
import n from "lodash/get.js";
import r from "lodash/pickBy.js";
import { createMachine as i, sendUpdate as a, assign as s } from "xstate";
import { runValidators as o } from "../../validators/index.mjs";
import { clearError as d, clearFormValues as l, clearTouched as c, clearValidationError as u, handleInput as m, handleSubmit as p, handleBlur as g, parsePhoneNumber as f, setCredentials as U, setFieldErrors as v, setRemoteError as S, setCodeDeliveryDetails as h, setUser as E } from "./actions.mjs";
function y({
  services: y
}) {
  return i({
    id: "signUpActor",
    initial: "init",
    predictableActionArguments: !0,
    states: {
      init: {
        always: [{
          target: "confirmSignUp",
          cond: "shouldInitConfirmSignUp"
        }, {
          target: "signUp"
        }]
      },
      signUp: {
        type: "parallel",
        exit: ["clearError", "clearFormValues", "clearTouched"],
        states: {
          validation: {
            initial: "pending",
            states: {
              pending: {
                invoke: {
                  src: "validateSignUp",
                  onDone: {
                    target: "valid",
                    actions: "clearValidationError"
                  },
                  onError: {
                    target: "invalid",
                    actions: "setFieldErrors"
                  }
                }
              },
              valid: {
                entry: "sendUpdate"
              },
              invalid: {
                entry: "sendUpdate"
              }
            },
            on: {
              CHANGE: {
                actions: "handleInput",
                target: ".pending"
              },
              BLUR: {
                actions: "handleBlur",
                target: ".pending"
              }
            }
          },
          submission: {
            initial: "idle",
            states: {
              idle: {
                entry: "sendUpdate",
                on: {
                  SUBMIT: {
                    actions: "handleSubmit",
                    target: "validate"
                  },
                  FEDERATED_SIGN_IN: "federatedSignIn"
                }
              },
              federatedSignIn: {
                tags: ["pending"],
                entry: ["sendUpdate", "clearError"],
                invoke: {
                  src: "federatedSignIn",
                  onDone: "#signUpActor.resolved",
                  onError: {
                    actions: "setRemoteError"
                  }
                }
              },
              validate: {
                entry: "sendUpdate",
                invoke: {
                  src: "validateSignUp",
                  onDone: {
                    target: "pending",
                    actions: "clearValidationError"
                  },
                  onError: {
                    target: "idle",
                    actions: "setFieldErrors"
                  }
                }
              },
              pending: {
                tags: ["pending"],
                entry: ["parsePhoneNumber", "sendUpdate", "clearError"],
                invoke: {
                  src: "signUp",
                  onDone: [{
                    cond: "shouldSkipConfirm",
                    target: "skipConfirm",
                    actions: ["setUser", "setCredentials"]
                  }, {
                    target: "resolved",
                    actions: ["setUser", "setCredentials", "setCodeDeliveryDetails"]
                  }],
                  onError: {
                    target: "idle",
                    actions: "setRemoteError"
                  }
                }
              },
              skipConfirm: {
                always: {
                  target: "#signUpActor.resolved",
                  actions: "setAutoSignInIntent"
                }
              },
              resolved: {
                type: "final",
                always: "#signUpActor.confirmSignUp"
              }
            }
          }
        }
      },
      confirmSignUp: {
        initial: "edit",
        states: {
          edit: {
            entry: "sendUpdate",
            on: {
              SUBMIT: {
                actions: "handleSubmit",
                target: "submit"
              },
              CHANGE: {
                actions: "handleInput"
              },
              BLUR: {
                actions: "handleBlur"
              },
              RESEND: "resend"
            }
          },
          resend: {
            tags: ["pending"],
            entry: "sendUpdate",
            invoke: {
              src: "resendConfirmationCode",
              onDone: {
                target: "edit"
              },
              onError: [{
                target: "#signUpActor.resolved",
                actions: "setAutoSignInIntent",
                cond: "isUserAlreadyConfirmed"
              }, {
                target: "edit",
                actions: "setRemoteError"
              }]
            }
          },
          submit: {
            tags: ["pending"],
            entry: ["sendUpdate", "clearError"],
            invoke: {
              src: "confirmSignUp",
              onDone: {
                target: "#signUpActor.resolved",
                actions: "setAutoSignInIntent"
              },
              onError: {
                target: "edit",
                actions: "setRemoteError"
              }
            }
          }
        }
      },
      resolved: {
        type: "final",
        data: (e, t) => {
          const {
            username: r,
            password: i
          } = e.authAttributes;
          return {
            user: n(t, "data.user") || e.user,
            authAttributes: {
              username: r,
              password: i
            },
            intent: e.intent
          };
        }
      }
    }
  }, {
    guards: {
      isUserAlreadyConfirmed: (e, t) => "User is already confirmed." === t.data.message,
      shouldInitConfirmSignUp: e => e.intent && "confirmSignUp" === e.intent,
      shouldSkipConfirm: (e, t) => t.data.userConfirmed
    },
    actions: {
      clearError: d,
      clearFormValues: l,
      clearTouched: c,
      clearValidationError: u,
      handleInput: m,
      handleSubmit: p,
      handleBlur: g,
      parsePhoneNumber: f,
      setCredentials: U,
      setFieldErrors: v,
      setRemoteError: S,
      setCodeDeliveryDetails: h,
      setUser: E,
      sendUpdate: a(),
      setAutoSignInIntent: s({
        intent: e => "confirmSignUp" === (null == e ? void 0 : e.intent) ? "autoSignInSubmit" : "autoSignIn"
      })
    },
    services: {
      confirmSignUp(t, r) {
        return e(this, void 0, void 0, function* () {
          const {
              user: e,
              authAttributes: r,
              formValues: i
            } = t,
            {
              confirmation_code: a
            } = i,
            s = n(e, "username") || n(r, "username");
          return yield y.handleConfirmSignUp({
            username: s,
            code: a
          });
        });
      },
      resendConfirmationCode(r, i) {
        return e(this, void 0, void 0, function* () {
          const {
              user: e,
              authAttributes: i
            } = r,
            a = n(e, "username") || n(i, "username");
          return t.resendSignUp(a);
        });
      },
      federatedSignIn(n, r) {
        return e(this, void 0, void 0, function* () {
          const {
            provider: e
          } = r.data;
          return yield t.federatedSignIn({
            provider: e
          });
        });
      },
      signUp(t, n) {
        return e(this, void 0, void 0, function* () {
          const {
              formValues: e,
              loginMechanisms: n
            } = t,
            [i = "username"] = n,
            {
              [i]: a,
              password: s
            } = e,
            o = r(e, (e, t) => {
              switch (t) {
                case "address":
                case "birthdate":
                case "email":
                case "family_name":
                case "gender":
                case "given_name":
                case "locale":
                case "middle_name":
                case "name":
                case "nickname":
                case "phone_number":
                case "picture":
                case "preferred_username":
                case "profile":
                case "updated_at":
                case "website":
                case "zoneinfo":
                  return !0;
                default:
                  return t.startsWith("custom:");
              }
            });
          return yield y.handleSignUp({
            username: a,
            password: s,
            attributes: o
          });
        });
      },
      validateSignUp(t, n) {
        return e(this, void 0, void 0, function* () {
          return o(t.formValues, t.touched, t.passwordSettings, [y.validateFormPassword, y.validateConfirmPassword, y.validatePreferredUsername, y.validateCustomSignUp]);
        });
      }
    }
  });
}
export { y as createSignUpMachine };