const e = {
  "Account recovery requires verified contact information": "Восстановление учетной записи требует проверки контактной информации",
  "Back to Sign In": "Назад, чтобы войти",
  "Change Password": "изменять пароль",
  Changing: "Изменение",
  Code: "Код",
  "Confirm Password": "Подтверждение пароля",
  "Confirm Sign Up": "Подтверждение зарегистрироваться",
  "Confirm SMS Code": "Подтверждение CMC-Код",
  "Confirm TOTP Code": "Подтверждение TOTP-Код",
  Confirm: "Подтверждать",
  "Confirmation Code": "код подтверждения",
  Confirming: "подтверждение",
  "Create a new account": "Создавать новую учетную запись",
  "Create Account": "Создать учетную запись",
  "Creating Account": "создание учетная запись",
  "Dismiss alert": "Закрыть оповещение",
  Email: "электронная почта",
  "Enter your code": "ввести ваш Код",
  "Enter your Email": "ввести ваш электронная почта",
  "Enter your phone number": "ввести ваш номер телефона",
  "Enter your username": "ввести ваш имя пользователя",
  "Forgot your password?": "Забыли ваш пароль?",
  "Hide password": "Скрывать пароль",
  "It may take a minute to arrive": "Доставка может занять некоторое время",
  Loading: "Загрузка",
  "New password": "Новый пароль",
  or: "или",
  Password: "Пароль",
  "Phone Number": "Номер телефона",
  "Resend Code": "Отправь еще раз Код",
  "Reset your password": "сброс ваш пароль",
  "Reset your Password": "сброс ваш Пароль",
  "Send code": "Отправлять Код",
  "Send Code": "Отправлять Код",
  Sending: "отправка",
  "Setup TOTP": "Настраивать TOTP",
  "Show password": "Показывать пароль",
  "Sign in to your account": "знак в свой аккаунт",
  "Sign In with Amazon": "знак в с Amazon",
  "Sign In with Apple": "знак в с Apple",
  "Sign In with Facebook": "знак в с Facebook",
  "Sign In with Google": "знак в с Google",
  "Sign in": "знак в",
  "Sign In": "знак в",
  "Signing in": "подписание в",
  Skip: "Пропускать",
  Submit: "Представлять на рассмотрение",
  Submitting: "Представив",
  Username: "Имя пользователя",
  "Verify Contact": "Проверить контакт",
  Verify: "Проверить",
  "We Emailed You": "Мы отправили вам электронное письмо",
  "We Sent A Code": "Мы отправили код",
  "We Texted You": "Мы отправили вам текстовое сообщение",
  "Your code is on the way. To log in, enter the code we emailed to": "Ваш код отправлен. Чтобы войти в систему, введите код, который мы отправили по электронной почте",
  "Your code is on the way. To log in, enter the code we sent you": "Ваш код отправлен. Чтобы войти в систему, введите код, который мы послали вам",
  "Your code is on the way. To log in, enter the code we texted to": "Ваш код отправлен. Чтобы войти в систему, введите код, который мы отправили текстовым сообщением"
};
export { e as ruDict };