import { __awaiter as t } from "tslib";
import { Hub as e } from "aws-amplify";
import { appendToCognitoUserAgent as n } from "@aws-amplify/auth";
import { waitFor as i } from "xstate/lib/waitFor.js";
import { ALLOWED_SPECIAL_CHARACTERS as a, emailRegex as o } from "./constants.mjs";
import { getActorState as s } from "./actor.mjs";
import { isFunction as r } from "../../utils/index.mjs";
const c = ({
    packageName: t,
    version: e
  }) => {
    n(`${t}/${e}`);
  },
  l = t => {
    const e = t.trim().split("");
    for (let t = 0; t < e.length; t++) t > 0 && t < e.length - 1 && (e[t] = "*");
    return e.join("");
  },
  u = t => {
    if (t.length < 4) return t;
    const e = t.split("");
    for (let t = 0; t < e.length - 4; t++) e[t] = "*";
    return e.join("");
  },
  m = e => t(void 0, void 0, void 0, function* () {
    try {
      yield i(e, t => s(t).matches("autoSignIn"));
    } catch (t) {}
  }),
  d = ({
    payload: {
      data: e,
      event: n
    }
  }, i, a) => t(void 0, void 0, void 0, function* () {
    const {
        send: t
      } = i,
      o = i.getSnapshot(),
      {
        onSignIn: c,
        onSignOut: l
      } = null != a ? a : {};
    switch (n) {
      case "tokenRefresh":
        o.matches("authenticated.idle") && t("TOKEN_REFRESH");
        break;
      case "autoSignIn":
        if (!o.matches("authenticated")) {
          yield m(i);
          const n = s(i.getSnapshot());
          (null == n ? void 0 : n.matches("autoSignIn")) && t({
            type: "AUTO_SIGN_IN",
            data: e
          });
        }
        break;
      case "autoSignIn_failure":
        {
          yield m(i);
          const n = s(i.getSnapshot());
          (null == n ? void 0 : n.matches("autoSignIn")) && t({
            type: "AUTO_SIGN_IN_FAILURE",
            data: e
          });
          break;
        }
      case "signIn":
        r(c) && c();
        break;
      case "signOut":
      case "tokenRefresh_failure":
        r(l) && l(), o.matches("authenticated.idle") && t("SIGN_OUT");
    }
  }),
  h = (t, n = d) => e.listen("auth", ((t, e) => n => {
    e(n, t);
  })(t, n), "authenticator-hub-handler"),
  p = t => a.some(e => t.includes(e)),
  f = (t, e, n) => encodeURI(`otpauth://totp/${t}:${e}?secret=${n}&issuer=${t}`);
function g(t, ...e) {
  return Object.entries(t).reduce((t, [n, i]) => Object.assign(Object.assign({}, t), {
    [n]: e.includes(n) ? i : null == i ? void 0 : i.trim()
  }), {});
}
const I = t => !!t && o.test(t);
export { l as censorAllButFirstAndLast, u as censorPhoneNumber, c as configureComponent, d as defaultAuthHubHandler, f as getTotpCodeURL, p as hasSpecialChars, I as isValidEmail, h as listenToAuthHub, g as trimValues };