import { __rest as t } from "tslib";
import { createMachine as o, forwardTo as i, assign as n, spawn as e, actions as r } from "xstate";
import { stopActor as s } from "./actions.mjs";
import { signInActor as a } from "./actors/signIn.mjs";
import { signOutActor as c } from "./actors/signOut.mjs";
import { resetPasswordActor as d } from "./actors/resetPassword.mjs";
import { defaultServices as u } from "./defaultServices.mjs";
import { createSignUpMachine as l } from "./signUp.mjs";
const {
  choose: g
} = r;
function v() {
  return o({
    id: "authenticator",
    initial: "idle",
    context: {
      user: void 0,
      config: {},
      services: u,
      actorRef: void 0,
      hasSetup: !1
    },
    predictableActionArguments: !0,
    states: {
      idle: {
        invoke: {
          src: "getCurrentUser",
          onDone: {
            actions: "setUser",
            target: "authenticated"
          },
          onError: {
            target: "setup"
          }
        }
      },
      setup: {
        initial: "waitConfig",
        states: {
          waitConfig: {
            on: {
              INIT: {
                actions: ["configure", "setHasSetup"],
                target: "applyConfig"
              }
            }
          },
          applyConfig: {
            invoke: {
              src: "getAmplifyConfig",
              onDone: {
                actions: "applyAmplifyConfig",
                target: "goToInitialState"
              }
            }
          },
          goToInitialState: {
            always: [{
              target: "#authenticator.signUp",
              cond: "isInitialStateSignUp"
            }, {
              target: "#authenticator.resetPassword",
              cond: "isInitialStateResetPassword"
            }, {
              target: "#authenticator.signIn"
            }]
          }
        }
      },
      signIn: {
        initial: "spawnActor",
        states: {
          spawnActor: {
            always: {
              actions: "spawnSignInActor",
              target: "runActor"
            }
          },
          runActor: {
            entry: "clearActorDoneData",
            exit: "stopSignInActor"
          }
        },
        on: {
          SIGN_UP: "signUp",
          RESET_PASSWORD: "resetPassword",
          "done.invoke.signInActor": [{
            target: "signUp",
            actions: "setActorDoneData",
            cond: "shouldRedirectToSignUp"
          }, {
            target: "resetPassword",
            actions: "setActorDoneData",
            cond: "shouldRedirectToResetPassword"
          }, {
            target: "authenticated",
            actions: "setActorDoneData"
          }]
        }
      },
      signUp: {
        initial: "spawnActor",
        states: {
          spawnActor: {
            always: {
              actions: "spawnSignUpActor",
              target: "runActor"
            }
          },
          runActor: {
            entry: "clearActorDoneData",
            exit: "stopSignUpActor"
          },
          autoSignIn: {
            invoke: {
              src: "getCurrentUser",
              onDone: "#authenticator.authenticated",
              onError: "#authenticator.setup.goToInitialState"
            }
          }
        },
        on: {
          SIGN_IN: "signIn",
          "done.invoke.signUpActor": {
            target: "#authenticator.signIn",
            actions: "setActorDoneData",
            cond: "shouldAutoSignIn"
          }
        }
      },
      resetPassword: {
        initial: "spawnActor",
        states: {
          spawnActor: {
            always: {
              actions: "spawnResetPasswordActor",
              target: "runActor"
            }
          },
          runActor: {
            entry: "clearActorDoneData",
            exit: "stopResetPasswordActor"
          }
        },
        on: {
          SIGN_IN: "signIn",
          "done.invoke.resetPasswordActor": {
            target: "signIn",
            actions: "setActorDoneData"
          }
        }
      },
      signOut: {
        initial: "spawnActor",
        states: {
          spawnActor: {
            always: {
              actions: "spawnSignOutActor",
              target: "runActor"
            }
          },
          runActor: {
            entry: "clearActorDoneData",
            exit: ["stopSignOutActor", "clearUser"]
          }
        },
        on: {
          "done.invoke.signOutActor": [{
            target: "setup",
            cond: "shouldSetup"
          }, {
            target: "setup.goToInitialState"
          }]
        }
      },
      authenticated: {
        initial: "idle",
        states: {
          idle: {
            on: {
              TOKEN_REFRESH: "refreshUser"
            }
          },
          refreshUser: {
            invoke: {
              src: "getCurrentUser",
              onDone: {
                actions: "setUser",
                target: "idle"
              },
              onError: {
                target: "#authenticator.signOut"
              }
            }
          }
        },
        on: {
          SIGN_OUT: "signOut"
        }
      }
    },
    on: {
      CHANGE: {
        actions: "forwardToActor"
      },
      BLUR: {
        actions: "forwardToActor"
      },
      SUBMIT: {
        actions: "forwardToActor"
      },
      FEDERATED_SIGN_IN: {
        actions: "forwardToActor"
      },
      AUTO_SIGN_IN: {
        actions: "forwardToActor"
      },
      RESEND: {
        actions: "forwardToActor"
      },
      SIGN_IN: {
        actions: "forwardToActor"
      },
      SKIP: {
        actions: "forwardToActor"
      }
    }
  }, {
    actions: {
      forwardToActor: g([{
        cond: "hasActor",
        actions: i(t => t.actorRef)
      }]),
      setUser: n({
        user: (t, o) => o.data
      }),
      setActorDoneData: n({
        actorDoneData: (t, o) => {
          var i, n;
          return {
            authAttributes: Object.assign({}, null === (i = o.data) || void 0 === i ? void 0 : i.authAttributes),
            intent: null === (n = o.data) || void 0 === n ? void 0 : n.intent
          };
        },
        user: (t, o) => {
          var i;
          return null === (i = o.data) || void 0 === i ? void 0 : i.user;
        }
      }),
      clearUser: n({
        user: void 0
      }),
      clearActorDoneData: n({
        actorDoneData: void 0
      }),
      applyAmplifyConfig: n({
        config(t, o) {
          var i, n, e, r, s, a, c, d, u;
          const l = null !== (n = null === (i = o.data.aws_cognito_username_attributes) || void 0 === i ? void 0 : i.map(t => t.toLowerCase())) && void 0 !== n ? n : [],
            g = null !== (r = null === (e = o.data.aws_cognito_verification_mechanisms) || void 0 === e ? void 0 : e.map(t => t.toLowerCase())) && void 0 !== r ? r : [],
            v = null !== (a = null === (s = o.data.aws_cognito_signup_attributes) || void 0 === s ? void 0 : s.map(t => t.toLowerCase())) && void 0 !== a ? a : [],
            A = null !== (d = null === (c = o.data.aws_cognito_social_providers) || void 0 === c ? void 0 : c.map(t => t.toLowerCase())) && void 0 !== d ? d : [],
            f = o.data.aws_cognito_password_protection_settings || {};
          0 === l.length && l.push("username");
          const {
            loginMechanisms: w,
            signUpAttributes: S,
            socialProviders: m,
            initialState: h,
            formFields: D
          } = t.config;
          return {
            loginMechanisms: null != w ? w : l,
            formFields: null !== (u = p(D)) && void 0 !== u ? u : {},
            passwordSettings: f,
            signUpAttributes: null != S ? S : Array.from(new Set([...g, ...v])),
            socialProviders: null != m ? m : A.sort(),
            initialState: h
          };
        }
      }),
      spawnSignInActor: n({
        actorRef: (t, o) => {
          var i, n, r, s, c, d, u;
          const {
              services: l
            } = t,
            g = a({
              services: l
            }).withContext({
              authAttributes: null !== (n = null === (i = t.actorDoneData) || void 0 === i ? void 0 : i.authAttributes) && void 0 !== n ? n : {},
              user: t.user,
              intent: null === (r = t.actorDoneData) || void 0 === r ? void 0 : r.intent,
              country_code: "+1",
              formValues: {},
              touched: {},
              validationError: {},
              passwordSettings: null === (s = t.config) || void 0 === s ? void 0 : s.passwordSettings,
              loginMechanisms: null === (c = t.config) || void 0 === c ? void 0 : c.loginMechanisms,
              socialProviders: null === (d = t.config) || void 0 === d ? void 0 : d.socialProviders,
              formFields: null === (u = t.config) || void 0 === u ? void 0 : u.formFields
            });
          return e(g, {
            name: "signInActor"
          });
        }
      }),
      spawnSignUpActor: n({
        actorRef: (t, o) => {
          var i, n, r, s, a, c, d;
          const {
              services: u
            } = t,
            g = l({
              services: u
            }).withContext({
              authAttributes: null !== (n = null === (i = t.actorDoneData) || void 0 === i ? void 0 : i.authAttributes) && void 0 !== n ? n : {},
              country_code: "+1",
              intent: null === (r = t.actorDoneData) || void 0 === r ? void 0 : r.intent,
              formValues: {},
              touched: {},
              validationError: {},
              loginMechanisms: null === (s = t.config) || void 0 === s ? void 0 : s.loginMechanisms,
              socialProviders: null === (a = t.config) || void 0 === a ? void 0 : a.socialProviders,
              formFields: null === (c = t.config) || void 0 === c ? void 0 : c.formFields,
              passwordSettings: null === (d = t.config) || void 0 === d ? void 0 : d.passwordSettings
            });
          return e(g, {
            name: "signUpActor"
          });
        }
      }),
      spawnResetPasswordActor: n({
        actorRef: (t, o) => {
          var i, n, r, s, a;
          const {
              services: c
            } = t,
            u = d({
              services: c
            }).withContext({
              formValues: {},
              touched: {},
              intent: null === (i = t.actorDoneData) || void 0 === i ? void 0 : i.intent,
              username: null === (r = null === (n = t.actorDoneData) || void 0 === n ? void 0 : n.authAttributes) || void 0 === r ? void 0 : r.username,
              formFields: null === (s = t.config) || void 0 === s ? void 0 : s.formFields,
              validationError: {},
              passwordSettings: null === (a = t.config) || void 0 === a ? void 0 : a.passwordSettings
            });
          return e(u, {
            name: "resetPasswordActor"
          });
        }
      }),
      spawnSignOutActor: n({
        actorRef: t => {
          const o = c.withContext({
            user: t.user
          });
          return e(o, {
            name: "signOutActor"
          });
        }
      }),
      stopSignInActor: s("signInActor"),
      stopSignUpActor: s("signUpActor"),
      stopResetPasswordActor: s("resetPasswordActor"),
      stopSignOutActor: s("signOutActor"),
      configure: n((o, i) => {
        const n = i.data,
          {
            services: e
          } = n,
          r = t(n, ["services"]);
        return {
          services: Object.assign(Object.assign({}, u), e),
          config: r
        };
      }),
      setHasSetup: n({
        hasSetup: !0
      })
    },
    guards: {
      isInitialStateSignUp: t => "signUp" === t.config.initialState,
      isInitialStateResetPassword: t => "resetPassword" === t.config.initialState,
      shouldRedirectToSignUp: (t, o) => {
        var i;
        return "confirmSignUp" === (null === (i = o.data) || void 0 === i ? void 0 : i.intent);
      },
      shouldRedirectToResetPassword: (t, o) => {
        var i;
        return "confirmPasswordReset" === (null === (i = o.data) || void 0 === i ? void 0 : i.intent);
      },
      shouldAutoSignIn: (t, o) => {
        var i, n;
        return "autoSignIn" === (null === (i = o.data) || void 0 === i ? void 0 : i.intent) || "autoSignInSubmit" === (null === (n = o.data) || void 0 === n ? void 0 : n.intent);
      },
      shouldSetup: t => !1 === t.hasSetup,
      hasActor: t => !!t.actorRef
    },
    services: {
      getCurrentUser: (t, o) => t.services.getCurrentUser(),
      getAmplifyConfig: (t, o) => t.services.getAmplifyConfig()
    }
  });
}
function p(t) {
  return t && Object.keys(t).forEach(o => {
    Object.keys(t[o]).forEach(i => {
      let n = t[o][i];
      n.required = n.isRequired;
    });
  }), t;
}
export { v as createAuthenticatorMachine };