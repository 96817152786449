import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, firstValueFrom } from 'rxjs';

import { isNotNil } from '@rpm/shared/utility';

import { AuthService } from './auth/auth.service';
import { PermissionService } from './auth/permission.service';
import { CurrentUserFragment } from './graphql';
import { ErrorService } from './shared/error/error.service';
import { ProgressService } from './shared/progress/progress.service';

@Component({
  selector: 'app-home',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class HomeComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private errors: ErrorService,
    private permissions: PermissionService,
    private progress: ProgressService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    // wait for user to load before navigating,
    // so that route guards will function as expected
    const user = await firstValueFrom(
      this.auth.user$.pipe(
        this.progress.watch(),
        this.errors.catch({ action: 'loading user' }),
        filter(isNotNil),
      ),
    );

    const { returnTo } = this.route.snapshot.queryParams;
    if (typeof returnTo === 'string') {
      void this.router.navigateByUrl(returnTo, { replaceUrl: true });
    } else {
      const path = this.getDefaultPathForRole(user);
      void this.router.navigate(path, { replaceUrl: true });
    }
  }

  private getDefaultPathForRole(user: CurrentUserFragment) {
    return this.permissions.can(user, 'viewShop') ? ['shop'] : ['analysis'];
  }
}
