const e = {
  "Account recovery requires verified contact information": "Gjenoppretting av konto krever verifisert kontaktinformajson",
  "Add your Profile": "Legg til profilen din",
  "Add your Website": "Legg til nettsiden din",
  "Back to Sign In": "Tilbake til innlogging",
  "Change Password": "Bytt passord",
  Changing: "Endre",
  Code: "Kode",
  "Confirm Password": "Bekreft passordet",
  "Confirm Sign Up": "Bekreft registrering",
  "Confirm SMS Code": "Bekreft SMS-kode",
  "Confirm TOTP Code": "Bekreft TOTP-kode",
  Confirm: "Bekreft",
  "Confirmation Code": "Bekreftelseskode",
  Confirming: "Bekrefter",
  "Create a new account": "Opprett en ny konto",
  "Create Account": "Opprett konto",
  "Creating Account": "Oppretter konto",
  "Dismiss alert": "Avvis varsel",
  Email: "E-post",
  "Enter your Birthdate": "Skriv inn fødselsdatoen din",
  "Enter your code": "Skriv inn koden din",
  "Enter your Confirmation Code": "Skriv inn bekreftelseskoden din",
  "Enter your Email": "Skriv inn e-postadressen din",
  "Enter your Family Name": "Skriv inn etternavnet ditt",
  "Enter your Given Name": "Skriv inn fornavnet ditt",
  "Enter your Middle Name": "Skriv inn mellomnavnet ditt",
  "Enter your Name": "Skriv inn navnet ditt",
  "Enter your Nickname": "Skriv inn kallenavnet ditt",
  "Enter your Password": "Skriv inn passordet ditt",
  "Enter your phone number": "Skriv inn telefonnummeret ditt",
  "Enter your Preferred Username": "Skriv inn det foretrukne brukernavnet ditt",
  "Enter your username": "Skriv inn brukernavnet ditt",
  "Forgot password?": "Glemt passord?",
  "Forgot your password?": "Glemt passordet ditt?",
  "Hide password": "Skjul passordet",
  "It may take a minute to arrive": "Det kan ta et minutt for å komme frem",
  Loading: "Laster inn",
  "New password": "Nytt passord",
  or: "eller",
  Password: "Passord",
  "Phone Number": "Telefonnummer",
  "Please confirm your Password": "Vennligst bekreft passordet ditt",
  "Resend Code": "Send koden på nytt",
  "Reset your password": "Nullstill passordet ditt",
  "Reset your Password": "Nullstill passordet ditt",
  "Send code": "Send kode",
  "Send Code": "Send kode",
  Sending: "Sender",
  "Setup TOTP": "Konfigurer TOTP",
  "Show password": "Vis passordet",
  "Sign in to your account": "Logg inn på kontoen din",
  "Sign In with Amazon": "Logg inn med Amazon",
  "Sign In with Apple": "Logg inn med Apple",
  "Sign In with Facebook": "Logg inn med Facebook",
  "Sign In with Google": "Logg inn med Google",
  "Sign in": "Logg inn",
  "Sign In": "Logg inn",
  "Signing in": "Logger inn",
  Skip: "Hopp over",
  Submit: "Send inn",
  Submitting: "Sender inn",
  Username: "Brukernavn",
  "Verify Contact": "Bekreft kontakt",
  Verify: "Bekreft",
  "We Emailed You": "Vi sendte deg en e-post",
  "We Sent A Code": "Vi sendte en kode",
  "We Texted You": "Vi sendte deg en tekstmelding",
  "Your code is on the way. To log in, enter the code we emailed to": "Koden din er på vei. For å logge inn, skriv inn koden vi sendte e-post til",
  "Your code is on the way. To log in, enter the code we sent you": "Koden din er på vei. For å logge inn, skriv inn koden vi sendte deg",
  "Your code is on the way. To log in, enter the code we texted to": "Koden din er på vei. For å logge inn, skriv inn koden vi sendte tekstmelding til",
  "An account with the given email already exists.": "Det finnes allerede en konto med denne e-postadressen",
  "Confirm a Code": "Bekreft koden",
  "Confirm Sign In": "Bekreft innlogging",
  "Create account": "Opprett konto",
  "Enter your password": "Skriv inn passordet ditt",
  "Forgot Password": "Glemt passordet",
  "Have an account? ": "Har en konto allerede? ",
  "Incorrect username or password": "Feil brukernavn eller passord",
  "Invalid password format": "Ugyldig passordformat",
  "Invalid phone number format": "Ugyldig telefonnummerformat",
  "Lost your code? ": "Mistet koden? ",
  "New Password": "Nytt passord",
  "No account? ": "Ingen konto? ",
  "Password attempts exceeded": "For mange mislykkede passordforsøk",
  "Reset password": "Nullstill passord",
  "Sign Out": "Logg ut",
  "Sign Up": "Registrering",
  "User already exists": "Brukeren finnes allerede",
  "User does not exist": "Brukeren finnes ikke",
  "Username cannot be empty": "Brukernavnet kan ikke være tomt"
};
export { e as nbDict };