import { Buffer } from 'buffer';

// https://github.com/aws-amplify/amplify-js/blob/7762f1a7076e622ec354c24539a3b57ce3ec4290/packages/amazon-cognito-identity-js/src/CognitoJwtToken.js#L44-L51
export function decodeTokenPayload(token: string) {
  const payload = token.split('.')[1];
  try {
    return JSON.parse(Buffer.from(payload, 'base64').toString('utf8')) as {
      exp: number;
    };
  } catch (err) {
    return undefined;
  }
}
