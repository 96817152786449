import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiService {
  private apiUrl = process.env['API_URL'];

  pingDb() {
    // ping request will result in a 500 HTTP error due to lambda timeout error if database was paused,
    // but will still cause it to resume
    return this.http.get<void>(`${this.apiUrl}/ping-db`).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  constructor(private http: HttpClient) {}
}
