<div [class.print-layout]="printModeActive$ | async">
  @if (!hideNotificationBanner) {
    <div
      class="site-banner"
      (click)="requestNotificationPermission()"
      matRipple
    >
      <mat-icon class="site-banner__icon">notifications</mat-icon>
      <div class="site-banner__content">
        Allow notifications to get alerted before being automatically signed
        out.
      </div>
      <button
        class="site-banner__dismiss"
        type="button"
        mat-icon-button
        (click)="$event.stopPropagation(); hideNotificationBanner = true"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  }

  @if ((printModeActive$ | async) === false) {
    <mat-toolbar class="site-toolbar">
      <div class="container flex items-center">
        @if (currentUser$ | async; as currentUser) {
          <a class="nav-brand" routerLink="/">
            <img
              [src]="currentUser.org.logoUrl || '/assets/brand.svg'"
              alt=""
            />
          </a>
        }
        <span class="flex-1"></span>
        <a
          *appIfUserCan="'viewOrgAnalysis'"
          class="nav-item"
          mat-button
          routerLink="/analysis"
          routerLinkActive="active"
          >Analysis</a
        >
        <a
          *appIfUserCan="'editUsers'"
          class="nav-item"
          mat-button
          routerLink="/admin/users"
          routerLinkActive="active"
          >Users</a
        >
        <app-presentation-toggle
          *appIfUserCan="'viewOrgAnalysis'"
          class="nav-item"
        />
        <button
          #menuTrigger="matMenuTrigger"
          class="nav-item"
          mat-icon-button
          [disableRipple]="true"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu
          #menu="matMenu"
          [overlapTrigger]="false"
          xPosition="before"
          class="menu-no-v-padding"
        >
          @if (currentUser$ | async; as currentUser) {
            <div class="user-info" mat-menu-item disabled>
              <div class="mat-body-strong">{{ currentUser.name }}</div>
              <div class="mat-caption u-text-muted">
                {{ currentUser.org.name }}
              </div>
              <button
                mat-stroked-button
                color="primary"
                routerLink="/account"
                (click)="menuTrigger.closeMenu()"
              >
                Manage account
              </button>
            </div>
            <mat-divider />
          }
          <button mat-menu-item class="text-center" (click)="logout()">
            Sign out
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
  }

  <router-outlet />
</div>
