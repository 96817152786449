import { __awaiter as e, __rest as t } from "tslib";
import { Auth as r } from "aws-amplify";
import n from "lodash/get.js";
import { createMachine as o, sendUpdate as i } from "xstate";
import { runValidators as s } from "../../../validators/index.mjs";
import { clearAttributeToVerify as a, clearChallengeName as d, clearRequiredAttributes as c, clearError as l, clearFormValues as u, clearTouched as g, clearUnverifiedContactMethods as m, clearValidationError as f, handleInput as p, handleSubmit as h, handleBlur as I, parsePhoneNumber as v, setChallengeName as S, setConfirmResetPasswordIntent as A, setConfirmSignUpIntent as U, setRequiredAttributes as E, setCredentials as y, setFieldErrors as T, setRemoteError as C, setTotpSecretCode as b, setUnverifiedContactMethods as N, setUser as w, setUsernameAuthAttributes as R } from "../actions.mjs";
import { defaultServices as P } from "../defaultServices.mjs";
import { isEmpty as V } from "../../../utils/index.mjs";
const _ = ["SMS_MFA", "SOFTWARE_TOKEN_MFA"],
  F = e => n(e, "data.challengeName"),
  O = (e, t) => e === t,
  j = e => _.includes(e);
function k({
  services: n
}) {
  return o({
    initial: "init",
    id: "signInActor",
    predictableActionArguments: !0,
    states: {
      init: {
        always: [{
          target: "autoSignIn.submit",
          cond: "shouldAutoSubmit"
        }, {
          target: "autoSignIn",
          cond: "shouldAutoSignIn"
        }, {
          target: "signIn"
        }]
      },
      signIn: {
        initial: "edit",
        exit: ["clearFormValues", "clearTouched"],
        states: {
          edit: {
            entry: "sendUpdate",
            on: {
              SUBMIT: {
                actions: "handleSubmit",
                target: "submit"
              },
              CHANGE: {
                actions: "handleInput"
              },
              FEDERATED_SIGN_IN: "federatedSignIn"
            }
          },
          federatedSignIn: {
            tags: ["pending"],
            entry: ["sendUpdate", "clearError"],
            invoke: {
              src: "federatedSignIn",
              onError: {
                actions: "setRemoteError"
              }
            }
          },
          submit: {
            tags: ["pending"],
            entry: ["parsePhoneNumber", "clearError", "sendUpdate"],
            invoke: {
              src: "signIn",
              onDone: [{
                cond: "shouldSetupTOTP",
                actions: ["setUser", "setChallengeName"],
                target: "#signInActor.setupTOTP"
              }, {
                cond: "shouldConfirmSignIn",
                actions: ["setUser", "setChallengeName"],
                target: "#signInActor.confirmSignIn"
              }, {
                cond: "shouldForceChangePassword",
                actions: ["setUser", "setChallengeName", "setRequiredAttributes"],
                target: "#signInActor.forceNewPassword"
              }, {
                actions: "setUser",
                target: "verifying"
              }],
              onError: [{
                cond: "shouldRedirectToConfirmSignUp",
                actions: ["setCredentials", "setConfirmSignUpIntent"],
                target: "rejected"
              }, {
                cond: "shouldRedirectToConfirmResetPassword",
                actions: ["setUsernameAuthAttributes", "setConfirmResetPasswordIntent"],
                target: "rejected"
              }, {
                actions: "setRemoteError",
                target: "edit"
              }]
            }
          },
          verifying: {
            tags: ["pending"],
            entry: ["clearError", "sendUpdate"],
            invoke: {
              src: "checkVerifiedContact",
              onDone: [{
                cond: "shouldRequestVerification",
                target: "#signInActor.verifyUser",
                actions: "setUnverifiedContactMethods"
              }, {
                target: "resolved"
              }],
              onError: {
                actions: "setRemoteError",
                target: "edit"
              }
            }
          },
          resolved: {
            always: "#signInActor.resolved"
          },
          rejected: {
            always: "#signInActor.rejected"
          }
        }
      },
      autoSignIn: {
        initial: "pending",
        states: {
          pending: {
            tags: ["pending"],
            entry: ["clearError", "sendUpdate"],
            on: {
              AUTO_SIGN_IN: [{
                cond: "shouldSetupTOTP",
                actions: ["setUser", "setChallengeName"],
                target: "#signInActor.setupTOTP"
              }, {
                cond: "shouldConfirmSignIn",
                actions: ["setUser", "setChallengeName"],
                target: "#signInActor.confirmSignIn"
              }, {
                cond: "shouldForceChangePassword",
                actions: ["setUser", "setChallengeName", "setRequiredAttributes"],
                target: "#signInActor.forceNewPassword"
              }, {
                actions: "setUser",
                target: "#signInActor.resolved"
              }],
              AUTO_SIGN_IN_FAILURE: {
                actions: "setRemoteError",
                target: "pending"
              }
            }
          },
          submit: {
            tags: ["pending"],
            entry: ["clearError", "sendUpdate"],
            invoke: {
              src: "signIn",
              onDone: [{
                cond: "shouldSetupTOTP",
                actions: ["setUser", "setChallengeName"],
                target: "#signInActor.setupTOTP"
              }, {
                cond: "shouldConfirmSignIn",
                actions: ["setUser", "setChallengeName"],
                target: "#signInActor.confirmSignIn"
              }, {
                cond: "shouldForceChangePassword",
                actions: ["setUser", "setChallengeName", "setRequiredAttributes"],
                target: "#signInActor.forceNewPassword"
              }, {
                actions: "setUser",
                target: "#signInActor.resolved"
              }],
              onError: {
                actions: "setRemoteError",
                target: "#signInActor.signIn"
              }
            }
          },
          resolved: {
            always: "#signInActor.resolved"
          },
          rejected: {
            always: "#signInActor.rejected"
          }
        }
      },
      confirmSignIn: {
        initial: "edit",
        exit: ["clearFormValues", "clearError", "clearTouched"],
        states: {
          edit: {
            entry: "sendUpdate",
            on: {
              SUBMIT: {
                actions: "handleSubmit",
                target: "submit"
              },
              SIGN_IN: "#signInActor.signIn",
              CHANGE: {
                actions: "handleInput"
              }
            }
          },
          submit: {
            tags: ["pending"],
            entry: ["clearError", "sendUpdate"],
            invoke: {
              src: "confirmSignIn",
              onDone: {
                target: "#signInActor.resolved",
                actions: ["setUser", "clearChallengeName", "clearRequiredAttributes"]
              },
              onError: {
                target: "edit",
                actions: "setRemoteError"
              }
            }
          }
        }
      },
      forceNewPassword: {
        type: "parallel",
        exit: ["clearFormValues", "clearError", "clearTouched"],
        states: {
          validation: {
            initial: "pending",
            states: {
              pending: {
                invoke: {
                  src: "validateFields",
                  onDone: {
                    target: "valid",
                    actions: "clearValidationError"
                  },
                  onError: {
                    target: "invalid",
                    actions: "setFieldErrors"
                  }
                }
              },
              valid: {
                entry: "sendUpdate"
              },
              invalid: {
                entry: "sendUpdate"
              }
            },
            on: {
              SIGN_IN: "#signInActor.signIn",
              CHANGE: {
                actions: "handleInput",
                target: ".pending"
              },
              BLUR: {
                actions: "handleBlur",
                target: ".pending"
              }
            }
          },
          submit: {
            initial: "idle",
            entry: "clearError",
            states: {
              idle: {
                entry: "sendUpdate",
                on: {
                  SUBMIT: {
                    actions: "handleSubmit",
                    target: "validate"
                  }
                }
              },
              validate: {
                entry: "sendUpdate",
                invoke: {
                  src: "validateFields",
                  onDone: {
                    target: "pending",
                    actions: "clearValidationError"
                  },
                  onError: {
                    target: "idle",
                    actions: "setFieldErrors"
                  }
                }
              },
              pending: {
                tags: ["pending"],
                entry: ["sendUpdate", "clearError"],
                invoke: {
                  src: "forceNewPassword",
                  onDone: [{
                    cond: "shouldConfirmSignIn",
                    actions: ["setUser", "setChallengeName"],
                    target: "#signInActor.confirmSignIn"
                  }, {
                    cond: "shouldSetupTOTP",
                    actions: ["setUser", "setChallengeName"],
                    target: "#signInActor.setupTOTP"
                  }, {
                    target: "resolved",
                    actions: ["setUser", "setCredentials"]
                  }],
                  onError: {
                    target: "idle",
                    actions: "setRemoteError"
                  }
                }
              },
              resolved: {
                type: "final",
                always: "#signInActor.resolved"
              }
            }
          }
        }
      },
      setupTOTP: {
        initial: "getTotpSecretCode",
        exit: ["clearFormValues", "clearError", "clearTouched"],
        states: {
          getTotpSecretCode: {
            invoke: {
              src: "getTotpSecretCode",
              onDone: {
                target: "edit",
                actions: "setTotpSecretCode"
              },
              onError: {
                target: "edit",
                actions: "setRemoteError"
              }
            }
          },
          edit: {
            entry: "sendUpdate",
            on: {
              SUBMIT: {
                actions: "handleSubmit",
                target: "submit"
              },
              SIGN_IN: "#signInActor.signIn",
              CHANGE: {
                actions: "handleInput"
              }
            }
          },
          submit: {
            tags: ["pending"],
            entry: ["sendUpdate", "clearError"],
            invoke: {
              src: "verifyTotpToken",
              onDone: {
                actions: ["clearChallengeName", "clearRequiredAttributes"],
                target: "#signInActor.resolved"
              },
              onError: {
                actions: "setRemoteError",
                target: "edit"
              }
            }
          }
        }
      },
      verifyUser: {
        initial: "edit",
        exit: ["clearFormValues", "clearError", "clearTouched"],
        states: {
          edit: {
            entry: "sendUpdate",
            on: {
              SUBMIT: {
                actions: "handleSubmit",
                target: "submit"
              },
              SKIP: "#signInActor.resolved",
              CHANGE: {
                actions: "handleInput"
              }
            }
          },
          submit: {
            tags: ["pending"],
            entry: "clearError",
            invoke: {
              src: "verifyUser",
              onDone: {
                target: "#signInActor.confirmVerifyUser"
              },
              onError: {
                actions: "setRemoteError",
                target: "edit"
              }
            }
          }
        }
      },
      confirmVerifyUser: {
        initial: "edit",
        exit: ["clearFormValues", "clearError", "clearUnverifiedContactMethods", "clearAttributeToVerify", "clearTouched"],
        states: {
          edit: {
            entry: "sendUpdate",
            on: {
              SUBMIT: {
                actions: "handleSubmit",
                target: "submit"
              },
              SKIP: "#signInActor.resolved",
              CHANGE: {
                actions: "handleInput"
              }
            }
          },
          submit: {
            tags: ["pending"],
            entry: "clearError",
            invoke: {
              src: "confirmVerifyUser",
              onDone: {
                target: "#signInActor.resolved"
              },
              onError: {
                actions: "setRemoteError",
                target: "edit"
              }
            }
          }
        }
      },
      resolved: {
        type: "final",
        data: e => ({
          user: e.user
        })
      },
      rejected: {
        type: "final",
        data: (e, t) => ({
          intent: e.redirectIntent,
          authAttributes: e.authAttributes
        })
      }
    }
  }, {
    actions: {
      clearAttributeToVerify: a,
      clearChallengeName: d,
      clearRequiredAttributes: c,
      clearError: l,
      clearFormValues: u,
      clearTouched: g,
      clearUnverifiedContactMethods: m,
      clearValidationError: f,
      handleInput: p,
      handleSubmit: h,
      handleBlur: I,
      parsePhoneNumber: v,
      setChallengeName: S,
      setConfirmResetPasswordIntent: A,
      setConfirmSignUpIntent: U,
      setRequiredAttributes: E,
      setCredentials: y,
      setFieldErrors: T,
      setRemoteError: C,
      setTotpSecretCode: b,
      setUnverifiedContactMethods: N,
      setUser: w,
      setUsernameAuthAttributes: R,
      sendUpdate: i()
    },
    guards: {
      shouldAutoSignIn: e => "autoSignIn" === (null == e ? void 0 : e.intent),
      shouldAutoSubmit: e => "autoSignInSubmit" === (null == e ? void 0 : e.intent),
      shouldConfirmSignIn: (e, t) => j(F(t)),
      shouldForceChangePassword: (e, t) => O(F(t), "NEW_PASSWORD_REQUIRED"),
      shouldRedirectToConfirmResetPassword: (e, t) => "PasswordResetRequiredException" === t.data.code,
      shouldRedirectToConfirmSignUp: (e, t) => "UserNotConfirmedException" === t.data.code,
      shouldRequestVerification: (e, t) => {
        const {
          unverified: r,
          verified: n
        } = t.data;
        return V(n) && !V(r);
      },
      shouldSetupTOTP: (e, t) => O(F(t), "MFA_SETUP")
    },
    services: {
      signIn(t) {
        return e(this, void 0, void 0, function* () {
          const {
              authAttributes: e = {},
              formValues: r = {}
            } = t,
            o = Object.assign(Object.assign({}, e), r),
            {
              username: i,
              password: s
            } = o;
          return yield n.handleSignIn({
            username: i,
            password: s
          });
        });
      },
      confirmSignIn(t) {
        return e(this, void 0, void 0, function* () {
          const {
              challengeName: e,
              user: o
            } = t,
            {
              confirmation_code: i
            } = t.formValues,
            s = j(e) ? e : void 0;
          return yield n.handleConfirmSignIn({
            user: o,
            code: i,
            mfaType: s
          }), yield r.currentAuthenticatedUser();
        });
      },
      forceNewPassword(n) {
        return e(this, void 0, void 0, function* () {
          const {
            user: e,
            formValues: o
          } = n;
          let i,
            {
              password: s,
              confirm_password: a,
              phone_number: d,
              country_code: c
            } = o,
            l = t(o, ["password", "confirm_password", "phone_number", "country_code"]);
          d && (i = `${c}${d}`.replace(/[^A-Z0-9+]/gi, ""), l = Object.assign(Object.assign({}, l), {
            phone_number: i
          }));
          try {
            const t = yield r.completeNewPassword(e, s, l);
            return t.challengeName ? t : r.currentAuthenticatedUser();
          } catch (e) {
            return Promise.reject(e);
          }
        });
      },
      getTotpSecretCode(t) {
        return e(this, void 0, void 0, function* () {
          const {
            user: e
          } = t;
          return r.setupTOTP(e);
        });
      },
      verifyTotpToken(t) {
        return e(this, void 0, void 0, function* () {
          const {
              formValues: e,
              user: n
            } = t,
            {
              confirmation_code: o
            } = e;
          return r.verifyTotpToken(n, o);
        });
      },
      federatedSignIn(t, n) {
        return e(this, void 0, void 0, function* () {
          const {
            provider: e
          } = n.data;
          return yield r.federatedSignIn({
            provider: e
          });
        });
      },
      checkVerifiedContact(t) {
        return e(this, void 0, void 0, function* () {
          const {
            user: e
          } = t;
          return yield r.verifiedContact(e);
        });
      },
      verifyUser(t) {
        return e(this, void 0, void 0, function* () {
          const {
              unverifiedAttr: e
            } = t.formValues,
            n = yield r.verifyCurrentUserAttribute(e);
          return t.attributeToVerify = e, n;
        });
      },
      confirmVerifyUser(t) {
        return e(this, void 0, void 0, function* () {
          const {
              attributeToVerify: e
            } = t,
            {
              confirmation_code: n
            } = t.formValues;
          return yield r.verifyCurrentUserAttributeSubmit(e, n);
        });
      },
      validateFields(t) {
        return e(this, void 0, void 0, function* () {
          return s(t.formValues, t.touched, t.passwordSettings, [P.validateFormPassword, P.validateConfirmPassword]);
        });
      }
    }
  });
}
export { k as signInActor };