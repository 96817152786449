import { __awaiter as e } from "tslib";
import { createMachine as r, sendUpdate as t } from "xstate";
import { runValidators as s } from "../../../validators/index.mjs";
import { clearError as a, clearFormValues as o, clearTouched as n, clearUsername as i, clearValidationError as d, handleInput as l, handleSubmit as c, handleBlur as m, resendCode as u, setFieldErrors as p, setRemoteError as g, setUsername as v } from "../actions.mjs";
import { defaultServices as E } from "../defaultServices.mjs";
function f({
  services: f
}) {
  return r({
    id: "resetPasswordActor",
    initial: "init",
    predictableActionArguments: !0,
    states: {
      init: {
        always: [{
          target: "confirmResetPassword",
          cond: "shouldAutoConfirmReset",
          actions: "resendCode"
        }, {
          target: "resetPassword"
        }]
      },
      resetPassword: {
        initial: "edit",
        exit: ["clearFormValues", "clearError", "clearTouched"],
        states: {
          edit: {
            entry: "sendUpdate",
            on: {
              SUBMIT: {
                actions: "handleSubmit",
                target: "submit"
              },
              CHANGE: {
                actions: "handleInput"
              },
              BLUR: {
                actions: "handleBlur"
              }
            }
          },
          submit: {
            tags: ["pending"],
            entry: ["sendUpdate", "setUsername", "clearError"],
            invoke: {
              src: "resetPassword",
              onDone: {
                target: "#resetPasswordActor.confirmResetPassword"
              },
              onError: {
                actions: ["setRemoteError"],
                target: "edit"
              }
            }
          }
        }
      },
      confirmResetPassword: {
        type: "parallel",
        exit: ["clearFormValues", "clearError", "clearUsername", "clearTouched"],
        states: {
          validation: {
            initial: "pending",
            states: {
              pending: {
                invoke: {
                  src: "validateFields",
                  onDone: {
                    target: "valid",
                    actions: "clearValidationError"
                  },
                  onError: {
                    target: "invalid",
                    actions: "setFieldErrors"
                  }
                }
              },
              valid: {
                entry: "sendUpdate"
              },
              invalid: {
                entry: "sendUpdate"
              }
            },
            on: {
              CHANGE: {
                actions: "handleInput",
                target: ".pending"
              },
              BLUR: {
                actions: "handleBlur",
                target: ".pending"
              }
            }
          },
          submission: {
            initial: "idle",
            states: {
              idle: {
                entry: "sendUpdate",
                on: {
                  SUBMIT: {
                    actions: "handleSubmit",
                    target: "validate"
                  },
                  RESEND: "resendCode",
                  CHANGE: {
                    actions: "handleInput"
                  },
                  BLUR: {
                    actions: "handleBlur"
                  }
                }
              },
              validate: {
                entry: "sendUpdate",
                invoke: {
                  src: "validateFields",
                  onDone: {
                    target: "pending",
                    actions: "clearValidationError"
                  },
                  onError: {
                    target: "idle",
                    actions: "setFieldErrors"
                  }
                }
              },
              resendCode: {
                tags: ["pending"],
                entry: ["clearError", "sendUpdate"],
                invoke: {
                  src: "resetPassword",
                  onDone: {
                    target: "idle"
                  },
                  onError: {
                    actions: "setRemoteError",
                    target: "idle"
                  }
                }
              },
              pending: {
                tags: ["pending"],
                entry: ["clearError", "sendUpdate"],
                invoke: {
                  src: "confirmResetPassword",
                  onDone: {
                    actions: "clearUsername",
                    target: "#resetPasswordActor.resolved"
                  },
                  onError: {
                    actions: "setRemoteError",
                    target: "idle"
                  }
                }
              }
            }
          }
        }
      },
      resolved: {
        type: "final"
      }
    }
  }, {
    actions: {
      clearError: a,
      clearFormValues: o,
      clearTouched: n,
      clearUsername: i,
      clearValidationError: d,
      handleInput: l,
      handleSubmit: c,
      handleBlur: m,
      resendCode: u,
      setFieldErrors: p,
      setRemoteError: g,
      setUsername: v,
      sendUpdate: t()
    },
    guards: {
      shouldAutoConfirmReset: (e, r) => !(!e.intent || "confirmPasswordReset" !== e.intent)
    },
    services: {
      resetPassword(r) {
        return e(this, void 0, void 0, function* () {
          const {
            username: e
          } = r;
          return f.handleForgotPassword(e);
        });
      },
      confirmResetPassword(r) {
        return e(this, void 0, void 0, function* () {
          const {
              username: e
            } = r,
            {
              confirmation_code: t,
              password: s
            } = r.formValues;
          return f.handleForgotPasswordSubmit({
            username: e,
            code: t,
            password: s
          });
        });
      },
      validateFields(r, t) {
        return e(this, void 0, void 0, function* () {
          return s(r.formValues, r.touched, r.passwordSettings, [E.validateFormPassword, E.validateConfirmPassword]);
        });
      }
    }
  });
}
export { f as resetPasswordActor };