const e = {
  "Account recovery requires verified contact information": "계정 복구를 위해 연락처 확인이 필요합니다",
  "Back to Sign In": "로그인으로 돌아가기",
  "Change Password": "비밀번호 변경하기",
  Changing: "변경중",
  Code: "코드",
  "Confirm Password": "비밀번호 재확인",
  "Confirm Sign Up": "회원가입 확인",
  "Confirm SMS Code": "휴대폰 본인 확인",
  "Confirm TOTP Code": "TOTP 인증번호 확인",
  Confirm: "확인",
  "Confirmation Code": "인증번호",
  Confirming: "확인중",
  "Create a new account": "회원가입",
  "Create Account": "회원가입",
  "Creating Account": "회원가입중",
  "Dismiss alert": "알림 무시",
  Email: "이메일",
  "Enter your code": "인증번호를 입력해주세요",
  "Enter your Email": "이메일 입력",
  "Enter your phone number": "전화번호 입력",
  "Enter your username": "아이디를 입력해주세요",
  "Hide password": "비밀번호 숨기기",
  "It may take a minute to arrive": "도착하는 데 1분 정도 걸릴 수 있습니다",
  Loading: "로딩중",
  "New password": "새 비밀번호",
  or: "또는",
  Password: "비밀번호",
  "Phone Number": "전화번호",
  "Resend Code": "인증번호 재전송",
  "Reset your password": "비밀번호 재설정",
  "Reset your Password": "비밀번호 재설정",
  "Send code": "인증코드 보내기",
  "Send Code": "코드 전송",
  Sending: "전송중",
  "Setup TOTP": "TOTP 설정하기",
  "Show password": "비밀번호 보이기",
  "Sign in to your account": "로그인",
  "Sign In with Amazon": "Amazon 로그인",
  "Sign In with Apple": "Apple 로그인",
  "Sign In with Facebook": "Facebook 로그인",
  "Sign In with Google": "Google 로그인",
  "Sign in": "로그인",
  "Sign In": "로그인",
  "Signing in": "로그인중",
  Skip: "다음에 하기",
  Submit: "확인",
  Submitting: "확인중",
  Username: "아이디",
  "Verify Contact": "연락처 확인",
  Verify: "인증",
  "We Emailed You": "이메일을 보냄",
  "We Sent A Code": "코드를 보냄",
  "We Texted You": "문자 메시지를 보냄",
  "Your code is on the way. To log in, enter the code we emailed to": "코드가 전송 중입니다. 로그인하려면 이메일로 전송한 코드를 입력하세요",
  "Your code is on the way. To log in, enter the code we sent you": "코드가 전송 중입니다. 로그인하려면 전송한 코드를 입력하세요",
  "Your code is on the way. To log in, enter the code we texted to": "코드가 전송 중입니다. 로그인하려면 문자 메시지로 전송한 코드를 입력하세요",
  Birthdate: "생년월일",
  "Family Name": "성",
  "Forgot your password?": "비밀번호를 잊으셨나요?",
  "Given Name": "이름",
  Name: "성함",
  Nickname: "닉네임",
  "Preferred Username": "닉네임",
  Profile: "프로필",
  "Reset Password": "비밀번호 재설정",
  Website: "웹사이트"
};
export { e as krDict };