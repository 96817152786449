import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { isString } from 'lodash';

import { AppTitleService } from '../shared/app-title.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatAnchor, RouterLink],
})
export class NotFoundComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private appTitle: AppTitleService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.appTitle.setTitle('404');

    const replaceUrl: unknown = this.activatedRoute.snapshot.queryParams['url'];
    if (isString(replaceUrl)) {
      this.location.replaceState(replaceUrl);
    }
  }
}
