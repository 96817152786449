import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatToolbar } from '@angular/material/toolbar';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { from, shareReplay } from 'rxjs';

import { AuthService } from './auth/auth.service';
import { IfUserCanDirective } from './auth/if-user-can.directive';
import { PresentationToggleComponent } from './presentation/presentation-toggle.component';
import { ErrorService } from './shared/error/error.service';
import { NotificationService } from './shared/notification.service';
import { PrintModeService } from './shared/print-mode.service';
import { ProgressService } from './shared/progress/progress.service';

const HIDE_NOTIFICATION_BANNER = 'HIDE_NOTIFICATION_BANNER';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatRipple,
    MatIcon,
    MatIconButton,
    MatToolbar,
    RouterLink,
    IfUserCanDirective,
    MatAnchor,
    RouterLinkActive,
    PresentationToggleComponent,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatButton,
    MatDivider,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class AppLayoutComponent {
  readonly currentUser$ = this.auth.user$.pipe(
    this.errors.catch({ action: 'loading user' }),
    shareReplay(1),
  );

  readonly printModeActive$ = this.printMode.isActive$;

  get hideNotificationBanner() {
    return (
      this._hideNotificationBanner ||
      !this.notificationService.supported ||
      this.notificationService.permission !== 'default'
    );
  }
  set hideNotificationBanner(hide: boolean) {
    this._hideNotificationBanner = hide;
    if (hide) {
      localStorage.setItem(HIDE_NOTIFICATION_BANNER, '1');
    } else {
      localStorage.removeItem(HIDE_NOTIFICATION_BANNER);
    }
  }
  private _hideNotificationBanner = !!localStorage.getItem(
    HIDE_NOTIFICATION_BANNER,
  );

  constructor(
    private auth: AuthService,
    private cd: ChangeDetectorRef,
    private errors: ErrorService,
    private notificationService: NotificationService,
    private printMode: PrintModeService,
    private progress: ProgressService,
    private router: Router,
  ) {}

  logout(): void {
    from(this.auth.logout())
      .pipe(this.progress.watch(), this.errors.catch({ action: 'logging out' }))
      .subscribe(() => {
        void this.router.navigate(['/']);
      });
  }

  async requestNotificationPermission() {
    if (this.notificationService.supported) {
      await this.notificationService.requestPermission();
      this.cd.markForCheck();
    }
  }
}
