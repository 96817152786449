import { translate as e } from "../../../i18n/translations.mjs";
const r = r => {
    const o = Object.assign({}, r);
    for (const [t, s] of Object.entries(r)) {
      const {
        label: r,
        placeholder: n
      } = s;
      o[t] = Object.assign(Object.assign({}, s), {
        label: r ? e(r) : void 0,
        placeholder: n ? e(n) : void 0
      });
    }
    return o;
  },
  o = e => Object.entries(e).sort((e, r) => (e[1].order || Number.MAX_VALUE) - (r[1].order || Number.MAX_VALUE)).filter(e => void 0 !== e[1]);
export { r as applyTranslation, o as sortFormFields };