import { __awaiter as e } from "tslib";
import { Auth as r } from "aws-amplify";
import { actions as t } from "xstate";
import "../../types/authenticator/user.mjs";
import "../../types/authenticator/attributes.mjs";
import "../../types/primitives/componentClassName.mjs";
import "../../i18n/translations.mjs";
import { trimValues as a } from "../../helpers/authenticator/utils.mjs";
import "../../helpers/accountSettings/utils.mjs";
const {
    assign: o,
    stop: s
  } = t,
  n = e => s(e),
  i = o({
    attributeToVerify: e => {}
  }),
  u = o({
    challengeName: e => {}
  }),
  m = o({
    requiredAttributes: e => {}
  }),
  l = o({
    remoteError: e => ""
  }),
  d = o({
    formValues: e => ({})
  }),
  c = o({
    touched: e => ({})
  }),
  p = o({
    unverifiedContactMethods: e => {}
  }),
  f = o({
    username: e => {}
  }),
  v = o({
    validationError: e => ({})
  }),
  h = o({
    totpSecretCode: (e, r) => r.data
  }),
  g = o({
    challengeName: (e, r) => {
      var t;
      return null === (t = r.data) || void 0 === t ? void 0 : t.challengeName;
    }
  }),
  b = o({
    requiredAttributes: (e, r) => {
      var t, a;
      return null === (a = null === (t = r.data) || void 0 === t ? void 0 : t.challengeParam) || void 0 === a ? void 0 : a.requiredAttributes;
    }
  }),
  V = o({
    redirectIntent: e => "confirmPasswordReset"
  }),
  j = o({
    redirectIntent: e => "confirmSignUp"
  }),
  y = o({
    authAttributes: (e, r) => {
      var t, a;
      const [o] = e.loginMechanisms;
      return {
        username: null !== (t = e.formValues[o]) && void 0 !== t ? t : e.formValues.username,
        password: null === (a = e.formValues) || void 0 === a ? void 0 : a.password
      };
    }
  }),
  O = o({
    validationError: (e, r) => r.data
  }),
  _ = o({
    remoteError: (e, r) => {
      var t;
      return "NoUserPoolError" === r.data.name ? "Configuration error (see console) – please contact the administrator" : (null === (t = r.data) || void 0 === t ? void 0 : t.message) || r.data;
    }
  }),
  w = o({
    unverifiedContactMethods: (e, r) => r.data.unverified
  }),
  A = o({
    user: (e, r) => r.data.user || r.data
  }),
  C = o({
    username: (e, r) => {
      let {
        formValues: {
          username: t,
          country_code: a
        }
      } = e;
      return a && (t = `${a}${t}`), t;
    }
  }),
  E = o({
    codeDeliveryDetails: (e, r) => r.data.codeDeliveryDetails
  }),
  N = o({
    authAttributes: (e, r) => ({
      username: e.formValues.username
    })
  }),
  $ = o({
    formValues: (e, r) => {
      const {
        name: t,
        value: a
      } = r.data;
      return Object.assign(Object.assign({}, e.formValues), {
        [t]: a
      });
    }
  }),
  D = o({
    formValues: (e, r) => {
      const t = Object.assign(Object.assign({}, e.formValues), r.data);
      return a(t, "password");
    }
  }),
  M = o({
    touched: (e, r) => {
      const {
        name: t
      } = r.data;
      return Object.assign(Object.assign({}, e.touched), {
        [`${t}`]: !0
      });
    }
  }),
  P = t => e(void 0, void 0, void 0, function* () {
    const {
      username: e
    } = t;
    return yield r.forgotPassword(e);
  }),
  q = o({
    formValues: (e, r) => {
      var t;
      const [a = "username"] = e.loginMechanisms;
      if (!e.formValues.phone_number && "phone_number" !== a) return e.formValues;
      const {
          formValues: o,
          country_code: s
        } = e,
        n = o.phone_number ? "phone_number" : "username",
        i = `${null !== (t = o.country_code) && void 0 !== t ? t : s}${o[n]}`.replace(/[^A-Z0-9+]/gi, ""),
        u = Object.assign(Object.assign({}, o), {
          [n]: i
        });
      return delete u.country_code, u;
    }
  });
export { i as clearAttributeToVerify, u as clearChallengeName, l as clearError, d as clearFormValues, m as clearRequiredAttributes, c as clearTouched, p as clearUnverifiedContactMethods, f as clearUsername, v as clearValidationError, M as handleBlur, $ as handleInput, D as handleSubmit, q as parsePhoneNumber, P as resendCode, g as setChallengeName, E as setCodeDeliveryDetails, V as setConfirmResetPasswordIntent, j as setConfirmSignUpIntent, y as setCredentials, O as setFieldErrors, _ as setRemoteError, b as setRequiredAttributes, h as setTotpSecretCode, w as setUnverifiedContactMethods, A as setUser, C as setUsername, N as setUsernameAuthAttributes, n as stopActor };