@if (value$ | async; as value) {
  <a
    mat-stroked-button
    color="primary"
    (click)="toggle()"
    matTooltip="Exit presentation mode"
  >
    <mat-icon class="u-icon-fill">present_to_all</mat-icon>
    {{ value.group.name }}
  </a>
} @else {
  <a
    class="nav-item"
    mat-icon-button
    matTooltip="Enter presentation mode"
    (click)="toggle()"
  >
    <mat-icon>present_to_all</mat-icon>
  </a>
}
