import "../../types/authenticator/user.mjs";
import { authFieldsWithDefaults as r } from "../../types/authenticator/attributes.mjs";
import "../../types/primitives/componentClassName.mjs";
const t = r => {
    const t = new FormData(r.target);
    return Object.fromEntries(t);
  },
  e = (r, t) => {
    let e = [];
    return r && (e = Object.keys(r).reduce((t, e) => {
      var o;
      return t.push([e, null === (o = r[e]) || void 0 === o ? void 0 : o.order]), t;
    }, []).sort((r, t) => r[1] - t[1]).filter(r => void 0 !== r[1]).map(r => r[0])), Array.from(new Set([...e, ...t]));
  },
  o = t => r.includes(t),
  s = r => {
    return r ? (t = r, Array.isArray(t) ? r : [r]) : null;
    var t;
  };
export { s as getErrors, t as getFormDataFromEvent, o as isAuthFieldWithDefaults, e as setFormOrder };