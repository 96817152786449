import { HttpErrorResponse } from '@angular/common/http';
import {
  EnvironmentProviders,
  importProvidersFrom,
  Injector,
  isDevMode,
  Provider,
} from '@angular/core';
import {
  ApolloClientOptions,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { toString } from 'lodash';

import { AuthService } from './auth/auth.service';

export function provideGraphqlClient(): Array<Provider | EnvironmentProviders> {
  return [
    importProvidersFrom(ApolloModule),
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApolloOptions,
      deps: [HttpLink, Injector],
    },
  ];
}

export function createApolloOptions(
  httpLink: HttpLink,
  injector: Injector,
): ApolloClientOptions<NormalizedCacheObject> {
  const httpHandler = httpLink.create({
    uri: process.env['GRAPHQL_URL'],
  });

  // Auth middleware that sets auth header on GraphQL requests
  const authHandler = setContext(async () => {
    const auth = injector.get<AuthService>(AuthService);

    try {
      const session = await auth.currentSession();
      const idToken = session?.getIdToken();

      if (idToken) {
        return {
          headers: {
            Authorization: `Bearer ${idToken.getJwtToken()}`,
          },
        };
      }
    } catch (error) {
      // ignore errors getting session
    }

    return {};
  });

  // retry requests, especially Lambda timeouts caused by Aurora cold starts
  const retryHandler = new RetryLink({
    attempts: {
      retryIf: (error) =>
        error instanceof HttpErrorResponse && error.status >= 500,
    },
  });

  return {
    connectToDevTools: isDevMode(),
    link: retryHandler.concat(authHandler).concat(httpHandler),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            shopMonth: (_, { args, toReference }) =>
              toReference({
                __typename: 'ShopMonth',
                id: toString(args?.['id']),
              }),
          },
        },
      },
    }),
  };
}
