const e = {
  "Account recovery requires verified contact information": "Hesap kurtarma, doğrulanmış iletişim bilgilerini gerektirir",
  "Back to Sign In": "Oturum Açmaya Geri Dön",
  "Change Password": "Şifreyi Değiştir",
  Changing: "Değiştiriliyor",
  Code: "Kod",
  "Confirm Password": "Şifreyi Doğrula",
  "Confirm Sign Up": "Kayıt İşlemini Doğrula",
  "Confirm SMS Code": "SMS Kodunu Doğrula",
  "Confirm TOTP Code": "Tek Kullanımlık Şifreyi Doğrula",
  Confirm: "Doğrula",
  "Confirmation Code": "Doğrulama Kodu",
  Confirming: "Doğrulanıyor",
  "Create a new account": "Yeni bir hesap oluştur",
  "Create Account": "Hesap Oluştur",
  "Creating Account": "Hesap Oluşturuluyor",
  "Dismiss alert": "Uyarıyı reddet",
  Email: "E-posta",
  "Enter your code": "Kodu girin",
  "Enter your Email": "E-posta adresinizi girin",
  "Enter your phone number": "Telefon numaranızı girin",
  "Enter your username": "Kullanıcı adınızı girin",
  "Forgot your password?": "Şifrenizi mi unuttunuz?",
  "Hide password": "Şifreyi gizle",
  "It may take a minute to arrive": "Kodun gelmesi bir dakika sürebilir",
  Loading: "Yükleniyor",
  "New password": "Yeni şifre",
  or: "veya",
  Password: "Şifre",
  "Phone Number": "Telefon Numarası",
  "Resend Code": "Kodu Yeniden Gönder",
  "Reset your password": "Şifrenizi sıfırlayın",
  "Reset your Password": "Şifrenizi Sıfırlayın",
  "Send code": "Kod gönder",
  "Send Code": "Kod Gönder",
  Sending: "Gönderiliyor",
  "Setup TOTP": "Tek kullanımlık şifre kurulumu yap",
  "Show password": "Şifreyi göster",
  "Sign in to your account": "Hesabınızda oturum açın",
  "Sign In with Amazon": "Amazon ile Oturum Aç",
  "Sign In with Apple": "Apple ile Oturum Aç",
  "Sign In with Facebook": "Facebook ile Oturum Aç",
  "Sign In with Google": "Google ile Oturum Aç",
  "Sign in": "Oturum aç",
  "Sign In": "Oturum Aç",
  "Signing in": "Oturum açılıyor",
  Skip: "Atla",
  Submit: "Gönder",
  Submitting: "Gönderiliyor",
  Username: "Kullanıcı adı",
  "Verify Contact": "Kişiyi Doğrula",
  Verify: "Doğrula",
  "We Emailed You": "Size E-posta Gönderdik",
  "We Sent A Code": "Bir Kod Gönderdik",
  "We Texted You": "Size Mesaj Gönderdik",
  "Your code is on the way. To log in, enter the code we emailed to": "Kodunuz yolda. Oturum açmak için, gönderdiğimiz e-postadaki kodu girin",
  "Your code is on the way. To log in, enter the code we sent you": "Kodunuz yolda. Oturum açmak için, size gönderdiğimiz kodu girin",
  "Your code is on the way. To log in, enter the code we texted to": "Kodunuz yolda. Oturum açmak için, gönderdiğimiz mesajdaki kodu girin",
  "An account with the given email already exists.": "Bu e-postaya ait zaten bir hesap var.",
  "Confirm Sign In": "Oturum Açmayı Doğrula",
  "Have an account? ": "Hesabınız var mı? ",
  "Incorrect username or password": "Yanlış kullanıcı adı ya da şifre",
  "Invalid password format": "Geçersiz parola formatı",
  "Invalid phone number format": "Geçersiz telefon numarası formatı",
  "Lost your code? ": "Kodu mu kaybettiniz? ",
  "No account? ": "Hesabınız yok mu? ",
  "Password attempts exceeded": "Maksimum oturum açma girişimi aşıldı",
  "Sign Out": "Çıkış yap",
  "Sign Up": "Kayıt Ol",
  "User already exists": "Bu kullanıcı zaten var",
  "User does not exist": "Böyle bir kullanıcı mevcut değil",
  "Username cannot be empty": "Kullanıcı adı boş olamaz"
};
export { e as trDict };