const e = {
  "Account recovery requires verified contact information": "Zurücksetzen des Account benötigt einen verifizierten Account",
  "Back to Sign In": "Zurück zur Anmeldung",
  "Change Password": "Passwort ändern",
  Changing: "Ändern von",
  Code: "Code",
  "Confirm Password": "Passwort bestätigen",
  "Please confirm your Password": "Bitte bestätigen Sie Ihr Passwort",
  "Confirm Sign Up": "Registrierung bestätigen",
  "Confirm SMS Code": "SMS-Code bestätigen",
  "Confirm TOTP Code": "TOTP-Code bestätigen",
  Confirm: "Bestätigen",
  "Confirmation Code": "Bestätigungs-Code",
  Confirming: "Wird bestätigt",
  "Create a new account": "Einen neuen Account erstellen",
  "Create Account": "Account erstellen",
  "Creating Account": "Account wird erstellt",
  "Dismiss alert": "Warnung verwerfen",
  Email: "Email",
  "Enter your code": "Geben Sie Ihren Code ein",
  "Enter your Email": "Geben Sie Ihre E-Mail ein",
  "Enter your phone number": "Geben Sie Ihre Telefonnummer ein",
  "Enter your username": "Geben Sie Ihren Benutzernamen ein",
  "Forgot your password?": "Passwort vergessen? ",
  "Hide password": "Passwort verbergen",
  "It may take a minute to arrive": "Es kann eine Minute dauern, bis er ankommt",
  Loading: "Wird geladen",
  "New password": "Neues Passwort",
  or: "oder",
  Password: "Passwort",
  "Phone Number": "Telefonnummer",
  "Resend Code": "Code erneut senden",
  "Reset your Password": "Zurücksetzen des Passwort",
  "Reset your password": "Zurücksetzen des passwort",
  "Send code": "Code senden",
  "Send Code": "Code senden",
  Sending: "Wird gesendet",
  "Setup TOTP": "TOTP einrichten",
  "Show password": "Passwort anzeigen",
  "Sign in to your account": "Melden Sie sich mit Ihrem Account an",
  "Sign In with Amazon": "Mit Amazon anmelden",
  "Sign In with Apple": "Mit Apple anmelden",
  "Sign In with Facebook": "Mit Facebook anmelden",
  "Sign In with Google": "Mit Google anmelden",
  "Sign in": "Anmelden",
  "Sign In": "Anmelden",
  "Signing in": "Wird angemeldet",
  Skip: "Überspringen",
  Submit: "Abschicken",
  Submitting: "Wird gesendet",
  Username: "Benutzername",
  "Verify Contact": "Kontakt verifizieren",
  Verify: "Verifizieren",
  "We Sent A Code": "Wir haben einen Code gesendet",
  "We Texted You": "Wir haben Ihnen eine SMS gesendet",
  "Your code is on the way. To log in, enter the code we sent you": "Ihr Code ist unterwegs. Um sich anzumelden, geben Sie den Code ein, den wir Ihnen gesendet haben",
  "An account with the given email already exists.": "Ein Account mit dieser Email existiert bereits.",
  "Confirm a Code": "Code bestätigen",
  "Confirm Sign In": "Anmeldung bestätigen",
  "Create account": "Hier registrieren",
  "Enter your password": "Geben Sie Ihr Passwort ein",
  "Enter your Password": "Geben Sie Ihr Passwort ein",
  "Sign Up with Facebook": "Mit Facebook registrieren",
  "Sign Up with Google": "Mit Google registrieren",
  "Forgot Password": "Passwort vergessen",
  "Have an account? ": "Schon registriert? ",
  "Incorrect username or password": "Falscher Benutzername oder falsches Passwort",
  "Invalid password format": "Ungültiges Passwort-Format",
  "Invalid phone number format": "Ungültiges Telefonummern-Format. Benutze eine Nummer im Format: +12345678900",
  "It may take a minute to arrive.": "Es könnte eine Minute dauern, bis der Code eintrifft.",
  "Lost your code? ": "Code verloren? ",
  "New Password": "Neues Passwort",
  "No account? ": "Kein Account? ",
  "Password attempts exceeded": "Die maximale Anzahl der fehlerhaften Anmeldeversuche wurde erreicht",
  "Reset password": "Passwort zurücksetzen",
  "Sign Out": "Abmelden",
  "Sign Up": "Registrieren",
  "User already exists": "Dieser Benutzer existiert bereits",
  "User does not exist": "Dieser Benutzer existiert nicht",
  "Username cannot be empty": "Benutzername darf nicht leer sein",
  "We Emailed You": "Email wurde versendet",
  "Your code is on the way. To log in, enter the code we emailed to": "Ihr Bestätigungscode ist unterwegs. Um sich einzuloggen geben Sie den Code ein, den wir verschickt haben an",
  "Your code is on the way. To log in, enter the code we texted to": "Ihr Bestätigungscode ist unterwegs. Um sich einzuloggen geben Sie den Code ein, den wir verschickt haben an"
};
export { e as deDict };