import { __awaiter as r } from "tslib";
import { Amplify as t, Auth as s } from "aws-amplify";
import "../../types/authenticator/user.mjs";
import "../../types/authenticator/attributes.mjs";
import "../../types/primitives/componentClassName.mjs";
import "../../i18n/translations.mjs";
import { hasSpecialChars as n } from "../../helpers/authenticator/utils.mjs";
import "../../helpers/accountSettings/utils.mjs";
const o = {
  getAmplifyConfig() {
    return r(this, void 0, void 0, function* () {
      return t.configure();
    });
  },
  getCurrentUser() {
    return r(this, void 0, void 0, function* () {
      return s.currentAuthenticatedUser();
    });
  },
  handleSignUp(t) {
    return r(this, void 0, void 0, function* () {
      return s.signUp(Object.assign(Object.assign({}, t), {
        autoSignIn: {
          enabled: !0
        }
      }));
    });
  },
  handleSignIn({
    username: t,
    password: n
  }) {
    return r(this, void 0, void 0, function* () {
      return s.signIn(t, n);
    });
  },
  handleConfirmSignIn({
    user: t,
    code: n,
    mfaType: o
  }) {
    return r(this, void 0, void 0, function* () {
      return s.confirmSignIn(t, n, o);
    });
  },
  handleConfirmSignUp({
    username: t,
    code: n
  }) {
    return r(this, void 0, void 0, function* () {
      return yield s.confirmSignUp(t, n);
    });
  },
  handleForgotPasswordSubmit({
    username: t,
    code: n,
    password: o
  }) {
    return r(this, void 0, void 0, function* () {
      return s.forgotPasswordSubmit(t, n, o);
    });
  },
  handleForgotPassword(t) {
    return r(this, void 0, void 0, function* () {
      return s.forgotPassword(t);
    });
  },
  validateCustomSignUp(t, s) {
    return r(this, void 0, void 0, function* () {});
  },
  validateFormPassword(t, s, o) {
    return r(this, void 0, void 0, function* () {
      const {
          password: r
        } = t,
        {
          password: e
        } = s;
      if (!e || !o) return null;
      const i = [],
        a = +(null == o ? void 0 : o.passwordPolicyMinLength);
      r.length < a && i.push(`Password must have at least ${a} characters`);
      const u = null == o ? void 0 : o.passwordPolicyCharacters;
      return null == u || u.forEach(t => {
        switch (t) {
          case "REQUIRES_LOWERCASE":
            /[a-z]/.test(r) || i.push("Password must have lower case letters");
            break;
          case "REQUIRES_UPPERCASE":
            /[A-Z]/.test(r) || i.push("Password must have upper case letters");
            break;
          case "REQUIRES_NUMBERS":
            /[0-9]/.test(r) || i.push("Password must have numbers");
            break;
          case "REQUIRES_SYMBOLS":
            n(r) || i.push("Password must have special characters");
        }
      }), 0 !== i.length ? {
        password: i
      } : null;
    });
  },
  validateConfirmPassword(t, s) {
    return r(this, void 0, void 0, function* () {
      const {
          password: r,
          confirm_password: n
        } = t,
        {
          confirm_password: o,
          password: e
        } = s;
      return r || n ? (r || n) && r !== n && (o && e || (null == r ? void 0 : r.length) >= 6 && (null == n ? void 0 : n.length) >= 6) ? {
        confirm_password: "Your passwords must match"
      } : void 0 : null;
    });
  },
  validatePreferredUsername(t, s) {
    return r(this, void 0, void 0, function* () {});
  }
};
export { o as defaultServices };