const t = t => {
  var n;
  const r = Object.assign({
    default: void 0
  }, t);
  return null !== (n = r.default) && void 0 !== n ? n : r;
};
function n(t) {
  return null != t && !Array.isArray(t) && "object" == typeof t;
}
function r(t) {
  return "string" == typeof t || "object" == typeof t && "[object String]" === Object.prototype.toString.call(t);
}
function e(t) {
  return n(t) && "[object Map]" === Object.prototype.toString.call(t);
}
function o(t) {
  return n(t) && "[object Set]" === Object.prototype.toString.call(t);
}
function u(t) {
  return void 0 === t;
}
function c(t) {
  return null == t;
}
function i(t) {
  if (null == t) return !0;
  if (n(t) && (e(t) || o(t))) return !t.size;
  if (n(t) && (r(t) || Array.isArray(t))) return !t.length;
  for (const n in t) if (s(t, n)) return !1;
  return !0;
}
function f(t) {
  return Array.isArray(t) && i(t);
}
function l(...t) {
  return t.every(f);
}
function a(t) {
  return n(t) && i(t);
}
function p(...t) {
  return t.every(a);
}
function y(t) {
  return r(t) ? t.charAt(0).toUpperCase() + t.slice(1) : "";
}
function s(t, n) {
  return null != t && Object.prototype.hasOwnProperty.call(t, n);
}
function b(t) {
  return "function" == typeof t;
}
const j = (t, n) => n ? `${t}--${n}` : "",
  g = (t, n, r) => r ? `${t}--${n}` : "";
function A(t) {
  return b(t);
}
function d(t, n) {
  return t.reduce((t, e) => `${t}${r(e) ? n(e) : ""}`, "");
}
function v(...t) {}
export { l as areEmptyArrays, p as areEmptyObjects, y as capitalize, j as classNameModifier, g as classNameModifierByFlag, s as has, i as isEmpty, b as isFunction, e as isMap, c as isNil, n as isObject, o as isSet, r as isString, A as isTypedFunction, u as isUndefined, v as noop, t as sanitizeNamespaceImport, d as templateJoin };