<table mat-table [dataSource]="legend$" class="h-padding-table w-full">
  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>User</th>
    <td mat-cell *matCellDef="let row">
      {{ row.user.name }}
      <div class="mat-caption u-text-muted">
        {{ row.user.shop?.name }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="alias">
    <th mat-header-cell *matHeaderCellDef>Alias</th>
    <td mat-cell *matCellDef="let row">
      {{ row.alias }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
