import { I18n as t } from "aws-amplify";
import { defaultTexts as n, deDict as r, enDict as e, esDict as i, frDict as o, idDict as s, itDict as a, jaDict as c, krDict as f, nbDict as m, nlDict as p, plDict as u, ptDict as d, zhDict as g, svDict as j, trDict as l, ruDict as b, heDict as h, uaDict as k } from "./dictionaries/index.mjs";
const x = Object.assign({}, n);
function v(n) {
  return t.get(n);
}
function w(n) {
  return t.get(n) !== n;
}
const y = {
  de: r,
  en: e,
  es: i,
  fr: o,
  id: s,
  it: a,
  ja: c,
  kr: f,
  ko: f,
  nb: m,
  nl: p,
  pl: u,
  pt: d,
  zh: g,
  sv: j,
  tr: l,
  ru: b,
  he: h,
  ua: k
};
export { x as DefaultTexts, w as hasTranslation, v as translate, y as translations };