import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { map, Observable } from 'rxjs';

import { UserAdminService } from '../admin/user-admin.service';
import { UserAdminDetailsFragment } from '../graphql';
import { AppTitleService } from '../shared/app-title.service';
import { ErrorService } from '../shared/error/error.service';
import { ProgressService } from '../shared/progress/progress.service';

import { PresentationService } from './presentation.service';

interface Item {
  user: UserAdminDetailsFragment;
  alias: string;
}

@Component({
  selector: 'app-presentation-legend',
  templateUrl: './presentation-legend.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
  ],
})
export class PresentationLegendComponent implements OnInit {
  readonly columns = ['alias', 'user'];
  readonly legend$: Observable<Item[]> = this.userAdmin.users().pipe(
    this.progress.watch(),
    this.errors.catch({ action: 'loading legend' }),
    map((result) =>
      result
        ? result.data.users.map((user) => ({
            user,
            alias: this.presentationService.getUserAlias(user),
          }))
        : [],
    ),
  );

  constructor(
    private appTitle: AppTitleService,
    private errors: ErrorService,
    private progress: ProgressService,
    private presentationService: PresentationService,
    private userAdmin: UserAdminService,
  ) {}

  ngOnInit(): void {
    this.appTitle.setTitle('Presentation Legend');
  }
}
