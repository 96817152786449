import { deDict as t } from "./authenticator/de.mjs";
import { enDict as s } from "./authenticator/en.mjs";
import { esDict as a } from "./authenticator/es.mjs";
import { frDict as i } from "./authenticator/fr.mjs";
import { itDict as r } from "./authenticator/it.mjs";
import { jaDict as o } from "./authenticator/ja.mjs";
import { krDict as c } from "./authenticator/kr.mjs";
import { nbDict as m } from "./authenticator/nb.mjs";
import { nlDict as e } from "./authenticator/nl.mjs";
import { plDict as n } from "./authenticator/pl.mjs";
import { ptDict as j } from "./authenticator/pt.mjs";
import { zhDict as u } from "./authenticator/zh.mjs";
import { svDict as p } from "./authenticator/sv.mjs";
import { idDict as f } from "./authenticator/id.mjs";
import { trDict as h } from "./authenticator/tr.mjs";
import { ruDict as b } from "./authenticator/ru.mjs";
import { heDict as g } from "./authenticator/he.mjs";
import { uaDict as O } from "./authenticator/ua.mjs";
import { defaultTexts as D } from "./authenticator/defaultTexts.mjs";
const d = Object.assign({}, t),
  l = Object.assign({}, s),
  x = Object.assign({}, a),
  k = Object.assign({}, i),
  v = Object.assign({}, r),
  z = Object.assign({}, o),
  T = Object.assign({}, c),
  q = Object.assign({}, m),
  w = Object.assign({}, e),
  y = Object.assign({}, n),
  A = Object.assign({}, j),
  B = Object.assign({}, u),
  C = Object.assign({}, p),
  E = Object.assign({}, f),
  F = Object.assign({}, h),
  G = Object.assign({}, b),
  H = Object.assign({}, g),
  I = Object.assign({}, O),
  J = Object.assign({}, D);
export { d as deDict, J as defaultTexts, l as enDict, x as esDict, k as frDict, H as heDict, E as idDict, v as itDict, z as jaDict, T as krDict, q as nbDict, w as nlDict, y as plDict, A as ptDict, G as ruDict, C as svDict, F as trDict, I as uaDict, B as zhDict };