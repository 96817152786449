const a = {
  "Account recovery requires verified contact information": "Pemulihan akun memerlukan informasi kontak terverifikasi",
  "Back to Sign In": "Kembali ke Masuk",
  "Change Password": "Ubah kata sandi",
  Changing: "Mengubah",
  Code: "Kode",
  "Confirm Password": "Konfirmasi kata sandi",
  "Confirm Sign Up": "Konfirmasi Pendaftaran",
  "Confirm SMS Code": "Konfirmasi Kode SMS",
  "Confirm TOTP Code": "Konfirmasi Kode TOTP",
  Confirm: "Konfirmasi",
  "Confirmation Code": "Kode Konfirmasi",
  Confirming: "Mengkonfirmasi",
  "Create a new account": "Buat akun baru",
  "Create Account": "Buat Akun",
  "Creating Account": "Membuat Akun",
  "Dismiss alert": "Hentikan pemberitahuan",
  Email: "Email",
  "Enter your code": "Masukkan kode anda",
  "Enter your Email": "Masukkan email anda",
  "Enter your phone number": "Masukkan nomor telepon anda",
  "Enter your username": "Masukkan nama akun anda",
  "Forgot your password?": "Lupa kata sandi? ",
  "Hide password": "Sembunyikan kata sandi",
  "It may take a minute to arrive": "Mungkin perlu waktu satu menit untuk tiba",
  Loading: "Memuat",
  "New password": "Kata sandi baru",
  or: "atau",
  Password: "Kata sandi",
  "Phone Number": "Nomor telepon",
  "Resend Code": "Kirim ulang kodenya",
  "Reset your Password": "Reset Kata Sandi",
  "Reset your password": "Ubah kata sandi anda",
  "Send code": "Kirim kode",
  "Send Code": "Kirim Kode",
  Sending: "Mengirim",
  "Setup TOTP": "Siapkan TOTP",
  "Show password": "Tampilkan kata sandi",
  "Sign in to your account": "Masuk akun anda",
  "Sign In with Amazon": "Masuk dengan Amazon",
  "Sign In with Apple": "Masuk dengan Apple",
  "Sign In with Facebook": "Masuk dengan Facebook",
  "Sign In with Google": "Masuk dengan Google",
  "Sign in": "Masuk",
  "Sign In": "Masuk",
  "Signing in": "Memasuki",
  Skip: "Lewati",
  Submit: "Ajukan",
  Submitting: "Mengajukan",
  Username: "Nama akun",
  "Verify Contact": "Verifikasi Kontak",
  Verify: "Verifikasi",
  "We Sent A Code": "Kami Mengirim Kode",
  "We Texted You": "Kami mengirim SMS kepada Anda",
  "Your code is on the way. To log in, enter the code we sent you": "Kode Anda segera hadir. Untuk masuk, masukkan kode yang kami kirimkan kepada Anda",
  "An account with the given email already exists.": "Akun dengan email tersebut sudah terdaftar.",
  "Attempt limit exceeded, please try after some time.": "Batas percobaan terlampaui, mohon coba lagi setelah beberapa waktu.",
  "Cannot reset password for the user as there is no registered/verified email or phone_number": "Tidak dapat mengatur ulang kata sandi karena tidak ada email terdaftar / terverifikasi atau nomor telepon",
  Change: "Ubah",
  "Confirm a Code": "Konfirmasi kode",
  "Create account": "Buat akun",
  "Enter your password": "Masukkan kata sandi anda",
  "Forgot Password": "Lupa kata sandi",
  "Have an account? ": "Sudah punya akun? ",
  Hello: "Halo",
  "Incorrect username or password.": "Nama akun atau kata sandi salah.",
  "Invalid phone number format": "Nomor telepon tidak sesuai dengan format.",
  "Invalid verification code provided, please try again.": "Kode verifikasi tidak sesuai, mohon coba lagi.",
  "It may take a minute to arrive.": "Mungkin perlu beberapa waktu untuk tiba.",
  "Lost your code? ": "Kode anda hilang?",
  Name: "Nama",
  "Network error": "Galat jaringan",
  "No account? ": "Tidak ada akun?",
  "Password did not conform with policy: Password not long enough": "Kata sandi tidak sesuai dengan aturan: Kata sandi kurang panjang",
  "Resend a Code": "Renvoyer un code",
  "Reset password": "Ubah kata sandi anda",
  Send: "Kirim",
  "Sign In with AWS": "Masuk dengan AWS",
  "Sign Up with Amazon": "Daftar dengan Amazon",
  "Sign Up with AWS": "Daftar dengan AWS",
  "Sign Up with Facebook": "Daftar dengan Facebook",
  "Sign Up with Google": "Daftar dengan Google",
  SMS: "SMS",
  "User already exists": "Akun sudah terdaftar",
  "User does not exist.": "Akun tidak terdaftar.",
  "User is disabled.": "Akun dinonaktifkan.",
  "Username cannot be empty": "Nama akun tidak boleh kosong",
  "Username/client id combination not found.": "Nama akun atau id tidak ditemukan.",
  "We Emailed You": "Kami mengirimkanmu email",
  "Your code is on the way. To log in, enter the code we emailed to": "Kode anda dalam pengiriman. Untuk masuk, masukkan kode yang kami emailkan ke",
  "Your code is on the way. To log in, enter the code we texted to": "Kode anda dalam pengiriman. Untuk masuk, masukkan kode yang kami tuliskan ke",
  "Your passwords must match": "Kata sandi harus sama"
};
export { a as idDict };