import { countryDialCodes as e } from "../../i18n/country-dial-codes.mjs";
import "../../i18n/translations.mjs";
const a = {
    birthdate: {
      label: "Birthdate",
      placeholder: "Enter your Birthdate",
      type: "date",
      autocomplete: "bday",
      isRequired: !0
    },
    confirmation_code: {
      label: "Confirmation Code",
      placeholder: "Enter your Confirmation Code",
      type: "number",
      autocomplete: "one-time-code",
      isRequired: !0
    },
    confirm_password: {
      label: "Confirm Password",
      placeholder: "Please confirm your Password",
      type: "password",
      autocomplete: "new-password",
      isRequired: !0
    },
    email: {
      label: "Email",
      placeholder: "Enter your Email",
      type: "email",
      autocomplete: "username",
      isRequired: !0
    },
    family_name: {
      label: "Family Name",
      placeholder: "Enter your Family Name",
      type: "text",
      autocomplete: "family-name",
      isRequired: !0
    },
    given_name: {
      label: "Given Name",
      placeholder: "Enter your Given Name",
      type: "text",
      autocomplete: "given-name",
      isRequired: !0
    },
    middle_name: {
      label: "Middle Name",
      placeholder: "Enter your Middle Name",
      type: "text",
      autocomplete: "additional-name",
      isRequired: !0
    },
    name: {
      label: "Name",
      placeholder: "Enter your Name",
      type: "text",
      autocomplete: "name",
      isRequired: !0
    },
    nickname: {
      label: "Nickname",
      placeholder: "Enter your Nickname",
      type: "text",
      autocomplete: "tel",
      isRequired: !0
    },
    password: {
      label: "Password",
      placeholder: "Enter your Password",
      type: "password",
      autocomplete: "new-password",
      isRequired: !0
    },
    phone_number: {
      label: "Phone Number",
      placeholder: "Enter your Phone Number",
      type: "tel",
      autocomplete: "tel",
      dialCode: "+1",
      dialCodeList: e,
      isRequired: !0
    },
    preferred_username: {
      label: "Preferred Username",
      placeholder: "Enter your Preferred Username",
      type: "text",
      isRequired: !0
    },
    profile: {
      label: "Profile",
      placeholder: "Add your Profile",
      type: "url",
      autocomplete: "url",
      isRequired: !0
    },
    website: {
      label: "Website",
      placeholder: "Add your Website",
      type: "url",
      autocomplete: "url",
      isRequired: !0
    },
    username: {
      label: "Username",
      placeholder: "Enter your Username",
      type: "text",
      autocomplete: "username",
      isRequired: !0
    }
  },
  r = ["^", "$", "*", ".", "[", "]", "{", "}", "(", ")", "?", '"', "!", "@", "#", "%", "&", "/", "\\", ",", ">", "<", "'", ":", ";", "|", "_", "~", "`", "=", "+", "-", " "],
  l = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export { r as ALLOWED_SPECIAL_CHARACTERS, a as defaultFormFieldOptions, l as emailRegex };