import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { SignUpParams as AmplifySignUpParams } from '@aws-amplify/auth';
import { AuthenticatorMachineOptions } from '@aws-amplify/ui';
import {
  AmplifyAuthenticatorModule,
  AuthenticatorService,
} from '@aws-amplify/ui-angular';
import { Auth } from 'aws-amplify';
import { isbot } from 'isbot';
import { filter, Subscription } from 'rxjs';

import { ApiService } from './api.service';
import { AuthService } from './auth/auth.service';
import { AppTitleService } from './shared/app-title.service';
import { DomainService } from './shared/domain.service';
import { ImgFallbackDirective } from './shared/img-fallback.directive';

interface SignUpParams {
  userId: string;
  code: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // `amplify-authenticator` doesn't work with push change detection
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    AmplifyAuthenticatorModule,
    ImgFallbackDirective,
    MatButton,
    RouterOutlet,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly services: AuthenticatorMachineOptions['services'] = {
    // eslint-disable-next-line @typescript-eslint/require-await
    validateCustomSignUp: async (formData: Record<string, string>) => {
      if (!formData['agree']) {
        return { agree: '' }; // require agree to be checked but don't show error message
      }
      return undefined;
    },
    handleSignUp: async ({ attributes, ...params }: AmplifySignUpParams) => {
      if (!this.signUpParams) {
        throw new Error('Missing sign up query params!');
      }

      const result = await Auth.signUp({
        ...params,
        attributes: {
          ...attributes,
          'custom:userId': this.signUpParams.userId,
        },
        validationData: {
          signUpCode: this.signUpParams.code,
        },
        autoSignIn: {
          enabled: true,
        },
      });

      void this.clearSignUpParams();

      return result;
    },
  };

  readonly defaultLogoUrl = '/assets/brand.svg';

  customLogoUrl = this.domainService.getLogoUrl();

  formFields: AuthenticatorMachineOptions['formFields'] = {
    signIn: {
      username: {
        labelHidden: true,
        placeholder: 'Email',
      },
      password: {
        labelHidden: true,
        placeholder: 'Password',
      },
    },
    resetPassword: {
      username: {
        labelHidden: true,
      },
    },
  };

  private signUpParams?: SignUpParams;

  private subscriptions: Subscription[] = [];

  constructor(
    private appTitle: AppTitleService,
    private api: ApiService,
    private auth: AuthService,
    readonly authenticator: AuthenticatorService,
    private domainService: DomainService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    // ping the database in case it was paused so that it starts resuming
    if (!isbot(navigator.userAgent)) {
      void this.api.pingDb().subscribe();
    }

    this.subscriptions = [
      // reset title when login page is displayed (i.e. no user)
      this.auth.session$.pipe(filter((session) => !session)).subscribe(() => {
        this.appTitle.resetTitle();
      }),
      // sign up if query params are present
      this.route.queryParams.subscribe(({ signUpUserId, code }) => {
        if (typeof signUpUserId === 'string' && typeof code === 'string') {
          void this.signUp({ userId: signUpUserId, code });
        }
      }),
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private async signUp(params: SignUpParams) {
    await this.auth.logout(); // in case someone else is logged in

    this.signUpParams = params;
    this.authenticator.toSignUp();

    // hack to prevent authenticator from returning to sign in after logout
    setTimeout(() => {
      this.authenticator.toSignUp();
    });
  }

  private clearSignUpParams() {
    this.signUpParams = undefined;

    return this.router.navigate([], {
      queryParams: {},
      relativeTo: this.route,
      replaceUrl: true,
    });
  }
}
