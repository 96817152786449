import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[appImgFallback]',
  standalone: true,
})
export class ImgFallbackDirective {
  @Input('appImgFallback') fallback?: string;

  constructor(private elementRef: ElementRef<HTMLImageElement>) {}

  @HostListener('error')
  private onError() {
    if (this.fallback && this.fallback !== this.elementRef.nativeElement.src) {
      this.elementRef.nativeElement.src = this.fallback;
    }
  }
}
