import { ICognitoStorage } from 'amazon-cognito-identity-js';

import { SECOND_MS, SESSION_EXPIRY_WINDOW_MS } from './constants';
import { decodeTokenPayload } from './decode-token-payload';

/**
 * Custom storage for Cognito security tokens.
 * https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#managing-security-tokens
 *
 * This implementation only loads the refresh token if the related access token hasn't been expired for too long,
 * otherwise it clears the storage. This effectively prevents the session from continuing after the user has been inactive.
 *
 * Related functionality is in {@link AuthService}, to handle session expiry while the app is running
 * (because storage is only accessed on page load).
 *
 * Amplify doesn't provide a way to customize this behaviour otherwise,
 * or even to get the token without automatically refreshing it:
 * https://github.com/aws-amplify/amplify-js/issues/11364
 */
export class AuthStorage implements ICognitoStorage {
  clear() {
    return localStorage.clear();
  }

  getItem(key: string) {
    if (key.endsWith('.refreshToken')) {
      const keyPrefix = key.split('.').slice(0, -1).join('.');
      const accessToken = localStorage.getItem(`${keyPrefix}.accessToken`);
      if (accessToken) {
        const payload = decodeTokenPayload(accessToken);
        if (
          payload &&
          Date.now() > payload.exp * SECOND_MS + SESSION_EXPIRY_WINDOW_MS
        ) {
          this.clear();
          return null;
        }
      }
    }
    return localStorage.getItem(key);
  }

  removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }
}
