import { getActorContext as e, getActorState as t } from "./actor.mjs";
const r = e => {
    const t = t => r => e({
      type: t,
      data: r
    });
    return {
      initializeMachine: t("INIT"),
      resendCode: t("RESEND"),
      signOut: t("SIGN_OUT"),
      submitForm: t("SUBMIT"),
      updateForm: t("CHANGE"),
      updateBlur: t("BLUR"),
      toFederatedSignIn: t("FEDERATED_SIGN_IN"),
      toResetPassword: t("RESET_PASSWORD"),
      toSignIn: t("SIGN_IN"),
      toSignUp: t("SIGN_UP"),
      skipVerification: t("SKIP")
    };
  },
  n = r => {
    var n, s, i, a, o;
    const c = null !== (n = e(r)) && void 0 !== n ? n : {},
      {
        codeDeliveryDetails: u,
        remoteError: d,
        unverifiedContactMethods: l,
        validationError: m,
        totpSecretCode: h = null
      } = c,
      {
        socialProviders: v
      } = null !== (i = null === (s = r.context) || void 0 === s ? void 0 : s.config) && void 0 !== i ? i : {},
      g = null !== (a = null == c ? void 0 : c.user) && void 0 !== a ? a : null === (o = r.context) || void 0 === o ? void 0 : o.user,
      f = m && Object.keys(m).length > 0,
      p = t(r),
      S = r.hasTag("pending") || (null == p ? void 0 : p.hasTag("pending")),
      I = (() => {
        switch (!0) {
          case r.matches("idle"):
            return "idle";
          case r.matches("setup"):
            return "setup";
          case r.matches("signOut"):
            return "signOut";
          case r.matches("authenticated"):
            return "authenticated";
          case null == p ? void 0 : p.matches("confirmSignUp"):
            return "confirmSignUp";
          case null == p ? void 0 : p.matches("confirmSignIn"):
            return "confirmSignIn";
          case null == p ? void 0 : p.matches("setupTOTP.edit"):
          case null == p ? void 0 : p.matches("setupTOTP.submit"):
            return "setupTOTP";
          case null == p ? void 0 : p.matches("signIn"):
            return "signIn";
          case null == p ? void 0 : p.matches("signUp"):
            return "signUp";
          case null == p ? void 0 : p.matches("forceNewPassword"):
            return "forceNewPassword";
          case null == p ? void 0 : p.matches("resetPassword"):
            return "resetPassword";
          case null == p ? void 0 : p.matches("confirmResetPassword"):
            return "confirmResetPassword";
          case null == p ? void 0 : p.matches("verifyUser"):
            return "verifyUser";
          case null == p ? void 0 : p.matches("confirmVerifyUser"):
            return "confirmVerifyUser";
          case null == p ? void 0 : p.matches("setupTOTP.getTotpSecretCode"):
          case r.matches("signIn.runActor"):
            return "transition";
          default:
            return console.debug("Cannot infer `route` from Authenticator state:", r.value), null;
        }
      })(),
      P = (e => {
        switch (e) {
          case "idle":
          case "setup":
            return "configuring";
          case "authenticated":
            return "authenticated";
          default:
            return "unauthenticated";
        }
      })(I);
    return {
      authStatus: P,
      codeDeliveryDetails: u,
      error: d,
      hasValidationErrors: f,
      isPending: S,
      route: I,
      socialProviders: v,
      totpSecretCode: h,
      unverifiedContactMethods: l,
      user: g,
      validationErrors: m
    };
  },
  s = ({
    send: e,
    state: t
  }) => {
    const s = r(e),
      i = n(t);
    return Object.assign(Object.assign({}, s), i);
  };
export { r as getSendEventAliases, n as getServiceContextFacade, s as getServiceFacade };