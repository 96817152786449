import "../../../types/authenticator/user.mjs";
import { isAuthFieldsWithDefaults as s } from "../../../types/authenticator/attributes.mjs";
import "../../../types/primitives/componentClassName.mjs";
import { getActorContext as t } from "../actor.mjs";
import { defaultFormFieldOptions as e } from "../constants.mjs";
import { defaultFormFieldsGetters as r, getAliasDefaultFormField as n } from "./defaults.mjs";
import { applyTranslation as i, sortFormFields as o } from "./util.mjs";
const a = (s, t) => (0, r[s])(t),
  c = (r, i) => {
    var o, a;
    const c = null === (a = null === (o = t(i)) || void 0 === o ? void 0 : o.formFields) || void 0 === a ? void 0 : a[r];
    return c && 0 !== Object.keys(c).length ? Object.entries(c).reduce((t, [o, a]) => {
      if ("signIn" !== r && "resetPassword" !== r || "username" !== o) {
        if (s(o)) {
          const s = e[o],
            r = Object.assign(Object.assign({}, s), a);
          return Object.assign(Object.assign({}, t), {
            [o]: r
          });
        }
        return Object.assign(Object.assign({}, t), {
          [o]: a
        });
      }
      {
        const s = n(i),
          e = Object.assign(Object.assign({}, s), a);
        return Object.assign(Object.assign({}, t), {
          [o]: e
        });
      }
    }, {}) : {};
  },
  m = (s, t) => {
    const e = a(s, t),
      r = c(s, t),
      n = Object.assign(Object.assign({}, e), r);
    return delete n.QR, i(n);
  },
  j = s => s.map(s => [s[0], Object.assign(Object.assign({}, s[1]), {
    order: void 0
  })]),
  b = (s, t) => {
    const e = m(s, t);
    return j(o(e));
  };
export { c as getCustomFormFields, a as getDefaultFormFields, m as getFormFields, b as getSortedFormFields, j as removeOrderKeys };