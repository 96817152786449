const e = {
  "Account recovery requires verified contact information": "账户恢复需要验证过的联系方式",
  "Back to Sign In": "回到登录",
  "Change Password": "更改密码",
  Changing: "正在修改",
  Code: "确认码",
  "Confirm Password": "确认密码",
  "Confirm Sign Up": "确认注册",
  "Confirm SMS Code": "确认短信验证码",
  "Confirm TOTP Code": "确认 TOTP 代码",
  Confirm: "确认",
  "Confirmation Code": "确认码",
  Confirming: "正在确认",
  "Create a new account": "创建新账户",
  "Create Account": "创建账户",
  "Creating Account": "正在创建账户",
  "Dismiss alert": "关闭警报",
  Email: "邮箱",
  "Enter your code": "输入验证码",
  "Enter your Email": "输入电子邮件",
  "Enter your phone number": "输入电话号码",
  "Enter your username": "输入用户名",
  "Forgot your password?": "忘记密码了？",
  "Hide password": "隐藏密码",
  "It may take a minute to arrive": "可能需要一分钟才能到达",
  Loading: "正在加载",
  "New password": "新密码",
  or: "或者",
  Password: "密码",
  "Phone Number": "电话",
  "Resend Code": "重发验证码",
  "Reset your password": "重置密码",
  "Reset your Password": "重置密码",
  "Send Code": "发送确认码",
  "Send code": "发送验证码",
  Sending: "正在发送",
  "Setup TOTP": "设置 TOTP",
  "Show password": "显示密码",
  "Sign in to your account": "登录账户",
  "Sign In with Amazon": "通过 Amazon 登录",
  "Sign In with Apple": "通过 Apple 登录",
  "Sign In with Facebook": "通过 Facebook 登录",
  "Sign In with Google": "通过 Google 登录",
  "Sign in": "登录",
  "Sign In": "登录",
  "Signing in": "正在登录",
  Skip: "跳过",
  Submit: "提交",
  Submitting: "正在提交",
  Username: "用户名",
  "Verify Contact": "验证联系方式",
  Verify: "验证",
  "We Emailed You": "我们给您发送了电子邮件",
  "We Sent A Code": "我们发送了代码",
  "We Texted You": "我们给您发送了短信",
  "Your code is on the way. To log in, enter the code we emailed to": "您的代码正在发送中。要登录，请输入我们通过电子邮件发送给以下人员的代码：",
  "Your code is on the way. To log in, enter the code we sent you": "您的代码正在发送中。要登录，请输入我们发送给您的代码",
  "Your code is on the way. To log in, enter the code we texted to": "您的代码正在发送中。要登录，请输入我们通过短信发送给以下人员的代码：",
  "Confirm a Code": "确认码",
  "Confirm Sign In": "确认登录",
  "Forgot Password": "忘记密码",
  "Incorrect username or password": "用户名或密码错误",
  "Invalid password format": "密码格式错误",
  "Invalid phone number format": "电话格式错误，请使用格式 +12345678900",
  "New Password": "新密码",
  "Resend a Code": "重发确认码",
  "Sign Out": "退出",
  "Sign Up": "注册",
  "User already exists": "用户已经存在",
  "User does not exist": "用户不存在"
};
export { e as zhDict };