import { STEPPER_GLOBAL_OPTIONS, StepperOptions } from '@angular/cdk/stepper';
import { provideHttpClient } from '@angular/common/http';
import {
  ApplicationConfig,
  ENVIRONMENT_INITIALIZER,
  ErrorHandler,
  importProvidersFrom,
  inject,
} from '@angular/core';
import {
  MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
  MatButtonToggleDefaultOptions,
} from '@angular/material/button-toggle';
import { MAT_CARD_CONFIG, MatCardConfig } from '@angular/material/card';
import { provideNativeDateAdapter } from '@angular/material/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import * as Sentry from '@sentry/angular';

import { routes } from './app.routes';
import { provideGraphqlClient } from './provide-graphql-client';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideNativeDateAdapter(),
    provideRouter(
      routes,
      withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
    ),
    provideGraphqlClient(),
    importProvidersFrom(AmplifyAuthenticatorModule),
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => {
        inject(MatIconRegistry).setDefaultFontSetClass(
          'material-symbols-outlined',
        );
      },
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
      useValue: {
        hideSingleSelectionIndicator: true,
      } satisfies MatButtonToggleDefaultOptions,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        hideRequiredMarker: true,
      } satisfies MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_CARD_CONFIG,
      useValue: { appearance: 'outlined' } satisfies MatCardConfig,
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true } satisfies StepperOptions,
    },
  ],
};
