<amplify-authenticator
  [loginMechanisms]="['email']"
  [formFields]="formFields"
  [services]="services"
>
  <ng-template amplifySlot="header">
    <img
      class="brand"
      [src]="customLogoUrl ?? defaultLogoUrl"
      [appImgFallback]="defaultLogoUrl"
    />
  </ng-template>

  <ng-template amplifySlot="confirm-reset-password-header">
    Please enter the code that was emailed to you:
  </ng-template>

  <ng-template
    amplifySlot="sign-up-form-fields"
    let-validationErrors="validationErrors"
  >
    <amplify-sign-up-form-fields />

    <amplify-checkbox
      [errorMessage]="validationErrors.agree"
      [hasError]="!!validationErrors.agree"
      name="agree"
      value="yes"
    >
      <span class="terms">
        I agree to the
        <a href="https://rpmtraining.net/terms" target="_blank">
          Terms of Use
        </a>
        and
        <a href="https://rpmtraining.net/privacy" target="_blank">
          Privacy Policy
        </a>
      </span>
    </amplify-checkbox>
  </ng-template>

  <ng-template amplifySlot="sign-in-footer">
    <div class="sign-in-footer">
      <button
        mat-button
        class="forgot-password"
        (click)="authenticator.toResetPassword()"
      >
        Forgot your password?
      </button>

      <div class="terms terms--muted">
        By continuing, you agree to our
        <a href="https://rpmtraining.net/terms" target="_blank">
          Terms of Use
        </a>
        and
        <a href="https://rpmtraining.net/privacy" target="_blank">
          Privacy Policy
        </a>
      </div>
    </div>
  </ng-template>

  <ng-template amplifySlot="authenticated">
    <router-outlet />
  </ng-template>
</amplify-authenticator>
