import "../../types/authenticator/user.mjs";
import { LoginMechanismArray as t } from "../../types/authenticator/attributes.mjs";
import "../../types/primitives/componentClassName.mjs";
const i = t => {
    var i;
    const s = null === (i = null == t ? void 0 : t.context.config) || void 0 === i ? void 0 : i.loginMechanisms,
      [n] = null != s ? s : ["username"];
    return n;
  },
  s = i => {
    var s;
    const n = (null === (s = i.config) || void 0 === s ? void 0 : s.loginMechanisms).filter(i => t.includes(i)),
      [o, ...e] = n;
    return {
      primaryAlias: o,
      secondaryAliases: e
    };
  };
export { s as getConfiguredAliases, i as getPrimaryAlias };