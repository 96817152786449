import { getActorContext as o, getActorState as s } from "../actor.mjs";
import { defaultFormFieldOptions as e } from "../constants.mjs";
import { getPrimaryAlias as r } from "../context.mjs";
import { isAuthFieldWithDefaults as t } from "../form.mjs";
const n = (s, r) => {
    const {
      country_code: t
    } = o(s);
    let n = e[r];
    const {
      type: a
    } = n;
    return "tel" === a && (n = Object.assign(Object.assign({}, n), {
      dialCode: t
    })), n;
  },
  a = o => {
    const s = r(o);
    return Object.assign(Object.assign({}, n(o, s)), {
      autocomplete: "username"
    });
  },
  c = o => ({
    confirmation_code: Object.assign(Object.assign({}, n(o, "confirmation_code")), {
      label: "Code *",
      placeholder: "Code"
    })
  }),
  i = {
    signIn: o => ({
      username: Object.assign({}, a(o)),
      password: Object.assign(Object.assign({}, n(o, "password")), {
        autocomplete: "current-password"
      })
    }),
    signUp: o => {
      const {
          loginMechanisms: s,
          signUpAttributes: e
        } = o.context.config,
        c = r(o),
        i = Array.from(new Set([...s, "password", "confirm_password", ...e])),
        d = {};
      for (const s of i) if (t(s)) {
        const e = s === c ? a(o) : n(o, s);
        d[s] = Object.assign({}, e);
      } else console.debug(`Authenticator does not have a default implementation for ${s}. Customize SignUp FormFields to add your own.`);
      return d;
    },
    confirmSignUp: o => ({
      confirmation_code: Object.assign(Object.assign({}, n(o, "confirmation_code")), {
        placeholder: "Enter your code"
      })
    }),
    confirmSignIn: c,
    forceNewPassword: o => {
      const e = s(o),
        {
          requiredAttributes: r
        } = e.context,
        a = Array.from(new Set(["password", "confirm_password", ...r])),
        c = {};
      for (const s of a) t(s) ? c[s] = Object.assign({}, n(o, s)) : console.debug(`Authenticator does not have a default implementation for ${s}. Customize ForceNewPassword FormFields to add your own.`);
      return c;
    },
    resetPassword: o => {
      const s = r(o),
        {
          label: t
        } = e[s];
      return {
        username: Object.assign(Object.assign({}, a(o)), {
          label: `Enter your ${t.toLowerCase()}`,
          placeholder: `Enter your ${t.toLowerCase()}`
        })
      };
    },
    confirmResetPassword: o => Object.assign(Object.assign({}, c(o)), {
      password: Object.assign(Object.assign({}, n(o, "password")), {
        label: "New Password",
        placeholder: "New Password"
      }),
      confirm_password: Object.assign(Object.assign({}, n(o, "confirm_password")), {
        label: "Confirm Password",
        placeholder: "Confirm Password"
      })
    }),
    confirmVerifyUser: c,
    setupTOTP: c
  };
export { i as defaultFormFieldsGetters, a as getAliasDefaultFormField };