import { isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { Amplify, I18n as AmplifyI18n } from 'aws-amplify';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { AuthStorage } from './app/auth/auth-storage';

Sentry.init({
  dsn: 'https://16328d016d9a3735507044d2428720a2@o366870.ingest.sentry.io/4505941846065152',
  environment: process.env['APP_STAGE'],
  enabled: !isDevMode(),
});

Amplify.configure({
  Auth: {
    region: process.env['APP_REGION'],
    userPoolId: process.env['AUTH_USER_POOL_ID'],
    identityPoolId: process.env['AUTH_IDENTITY_POOL_ID'],
    userPoolWebClientId: process.env['AUTH_USER_POOL_CLIENT_ID'],
    authenticationFlowType: 'USER_PASSWORD_AUTH', // to support migrate trigger
    storage: new AuthStorage(),
  },
});

AmplifyI18n.putVocabulariesForLanguage('en', {
  // this is more clear in case legacy user exists and can be migrated, but legacy password was entered incorrectly
  'User does not exist.': 'Incorrect email or password.',
  'Incorrect username or password.': 'Incorrect email or password.',
  'Username/client id combination not found.': 'Email not found.',
});

bootstrapApplication(AppComponent, appConfig)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
