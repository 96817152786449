import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, map, shareReplay, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrintModeService {
  // enable print mode if URL has `print` query parameter
  // NOTE: this is done on NavigationStart so that it's early enough to avoid non-print elements appearing
  readonly isActive$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationStart),
    map((event) => (event as NavigationStart).url),
    startWith(this.router.url),
    map((url) => !!this.router.parseUrl(url).queryParams['print']),
    shareReplay(1),
  );

  constructor(private router: Router) {}
}
